import Vue from 'vue';
import VueI18n from 'vue-i18n';

function pluralizationMidifier(str, num) {
  const strs = str.split('|').map((s) => s.trim());
  return strs.length === 3 ? strs[num] : strs[num - 1];
}

Vue.use(VueI18n);

const fallbackLocale = 'pt-BR';
const i18n = new VueI18n({
  fallbackLocale,
  modifiers: {
    none: (str) => pluralizationMidifier(str, 0),
    single: (str) => pluralizationMidifier(str, 1),
    many: (str) => pluralizationMidifier(str, 2),
  },
});

const loadedLangs = [];

function $setLangue(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return Promise.resolve(lang);
}

export function setLangue(lang) {
  if (loadedLangs.includes(lang)) {
    if (i18n.locale === lang) {
      return Promise.resolve(lang);
    }

    return $setLangue(lang);
  }

  return import('../locales/' + lang + '.json') // eslint-disable-line
    .then(({ default: langObject }) => {
      i18n.setLocaleMessage(lang, langObject.messages);
      i18n.setDateTimeFormat(lang, langObject['date-time-formats']);
      i18n.setNumberFormat(lang, langObject['number-formats']);
      loadedLangs.push(lang);

      return $setLangue(lang);
    })
    .catch(() => $setLangue(fallbackLocale));
}

setLangue(fallbackLocale);
export default i18n;
