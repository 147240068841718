<template>
  <b2b-overlay v-model="isActive" role="dialog">
    <b2b-card width="100vw" fill-height>
      <div class="b2b-envio-customers--header">
        <b2b-card-title v-text="$_title" row />
        <b2b-button @click.stop="$_onClickCancel" v-text="$t('x')" class="message--close" dense />
      </div>

      <div class="b2b-envio-customers--loading" v-if="loading">
        <b2b-loading />
      </div>

      <b2b-card-text class="mt--2" v-if="!loading">
        <b2b-grid-row>
          <b2b-grid-column cols="3">
            <b2b-select clearable
              v-model="filtros.invitations"
              :items="invitationsStates"
              :label="$tc('resources.invitations.resource', 2)"
            />
          </b2b-grid-column>

          <b2b-grid-column cols="3">
            <b2b-select clearable
              v-model="filtros.state"
              :items="states"
              :label="$t('resources.customers.state')"
            />
          </b2b-grid-column>

          <b2b-grid-column cols="3">
            <b2b-dialog-cities
              v-model="filtros.cities"
              :state="filtros.state"
              queryModel="QueryCustomerCities"
            />
          </b2b-grid-column>

           <b2b-grid-column cols="3">
            <b2b-select clearable
              v-model="filtros.updateDate"
              :items="updateDate"
              :label="$t('resources.customers.updateDate')"
            />
          </b2b-grid-column>

        </b2b-grid-row>

        <b2b-data-table-envios
          :query.sync="query"
          :invitations="filtros.invitations"
          :state="filtros.state"
          :cities="filtros.cities"
          :updateDate="filtros.updateDate"
          :totalEnvios="totalEnvio"
          @get-emails="$_getEmailsDataTable"
          @get-envioFunction="$_getTotalEnvio"
        />
      </b2b-card-text>

      <b2b-card-actions>
        <b2b-card-text row >
          <b v-text="$tc('resources.customers.total-sent', 1)" />
          <p class="b2b-envio-customers-total-send">
            {{ totalEnvio.quantidade }}/{{ totalEnvio.maximo }}
          </p>
        </b2b-card-text>

        <b2b-button
          @click.stop="$_onClickOk"
          v-text="titleButtonEnvio"
          :disabled="disabledButtonEnvio || loading"
          :loading="loadingButtonEnvio"
          dense />
      </b2b-card-actions>
    </b2b-card>
  </b2b-overlay>
</template>

<script>
import ActionResolver from '../../mixins/action-resolver';
import ActivableMixin from '../../mixins/base/activable-mixin';

import B2bOverlay from '../core/b2b-overlay.vue';
import B2bCard from '../core/card/b2b-card.vue';
import B2bCardTitle from '../core/card/b2b-card-title.vue';
import B2bCardActions from '../core/card/b2b-card-actions.vue';
import B2bCardText from '../core/card/b2b-card-text.vue';
import B2bButton from '../core/buttons/b2b-button.vue';
import B2bDataTableEnvios from '../data-tables/b2b-data-table-envios.vue';

import B2bGridRow from '../core/grid/b2b-grid-row.vue';
import B2bGridColumn from '../core/grid/b2b-grid-column.vue';
import B2bSelect from '../core/form/b2b-select.vue';
import B2bDialogCities from './b2b-dialog-cities.vue';

import B2bLoading from '../core/b2b-loading.vue';

export default {
  name: 'b2b-dialog-envio-customers',

  components: {
    B2bOverlay,
    B2bCard,
    B2bCardTitle,
    B2bDataTableEnvios,
    B2bButton,
    B2bGridRow,
    B2bGridColumn,
    B2bSelect,
    B2bDialogCities,
    B2bLoading,
    B2bCardActions,
    B2bCardText,
  },

  data() {
    return {
      loading: false,
      dataTableKey: 0,
      checkbox: false,
      disabledButtonEnvio: false,
      titleButtonEnvio: this.$tc('labels.envioCustomers'),
      loadingButtonEnvio: false,
      totalEnvio: {
        quantidade: 0,
        maximo: 0,
      },

      query: {
        page: 1,
      },

      filtros: {
        invitations: 'without',
        state: null,
        cities: null,
        updateDate: null,
      },

      states: [],
      updateDate: [
        {
          text: this.$t('resources.updateDate.oneDay'),
          value: 1,
        },
        {
          text: this.$t('resources.updateDate.sevenDays'),
          value: 7,
        },
        {
          text: this.$t('resources.updateDate.fifteenDays'),
          value: 15,
        },
        {
          text: this.$t('resources.updateDate.thirtyDays'),
          value: 30,
        },
      ],
      invitationsStates: [
        {
          text: this.$t('resources.invitations.without'),
          value: 'without',
        },
        {
          text: this.$t('resources.invitations.expired-at'),
          value: 'expired-at',
        },
      ],

      emailsCheck: [],
    };
  },

  mixins: [
    ActionResolver(),
    ActivableMixin(),
  ],

  computed: {
    $_title() {
      return this.title || this.$tc('labels.envioCustomers');
    },

    $_company() {
      return this.$store.getters['session/company'] || {};
    },
  },

  methods: {
    async $_loadStates() {
      this.loading = true;

      if (this.ignoreNextLoad) {
        this.ignoreNextLoad = false;
        return;
      }

      const { id } = this.$_company;
      const result = await this.$_actionResolver({
        action: 'customers/getEnvioCustomers',
        params: {
          ignoreInvitationType: true,
          companyId: id,
          getCities: false,
        },
      });

      const { items } = result;
      const newStates = [];
      items.forEach(({ uf }) => {
        if (!newStates.includes(uf)) {
          newStates.push(uf);
        }
      });

      newStates.sort();
      this.states = newStates.map((item) => ({
        value: item,
        text: item,
      }));

      this.loading = false;
    },

    $_onClickCancel() {
      this.isActive = false;
      this.checkbox = false;
    },

    $_getEmailsDataTable(item) {
      this.emailsCheck = item;
    },

    async $_onClickOk() {
      this.$_changeButtonEnvio(true, this.$t('labels.envioCustomersLoading'));

      const emails = this.emailsCheck.map((item) => ({
        userEmail: item.usuarioEmail,
        codeChecker: item.cnpj,
        roles: 'customer',
      }));

      if (emails.length === 0) {
        this.$hub.emit('dialog:error', new Error(this.$t('resources.customers.not-selected')));
        this.$_changeButtonEnvio();
        return;
      }

      const { id } = this.$_company;
      const result = await this.$_actionResolver({
        action: 'customers/postEnvioCustomers',
        params: {
          emails,
          companyId: id,
        },

      });

      if (result.success) {
        this.emailsCheck = [];
        this.$hub.emit('dialog:watch', this.$t('resources.customers.sending-email'), emails.length * 3000);
        this.loading = true;

        setTimeout(async () => {
          this.$hub.emit('dialog:ok', this.$t('resources.customers.send-success'));
          this.$_changeButtonEnvio();

          await this.$_loadStates();
          await this.$_getTotalEnvio();
        }, (emails.length * 3000));
        this.loading = false;
      } else {
        const errorMessage = `${this.$t('resources.customers.send-error')}\n${result.message}`;
        this.$hub.emit('dialog:error', new Error(errorMessage));
        this.$_changeButtonEnvio();

        await this.$_loadStates();
        await this.$_getTotalEnvio();
      }
    },

    async $_getTotalEnvio() {
      const { id } = this.$_company;
      const result = await this.$_actionResolver({
        action: 'customers/getTotalEnvioCustomers',
        params: {
          companyId: id,
        },
      });

      this.totalEnvio = result;
    },

    $_changeButtonEnvio(action = false, text = this.$t('labels.envioCustomers')) {
      this.loadingButtonEnvio = action;
      this.disabledButtonEnvio = action;
      this.titleButtonEnvio = text;
    },
  },

  async created() {
    await this.$_loadStates();
    await this.$_getTotalEnvio();
  },

  watch: {
    'filtros.states': function onWatchQueryState(newValue, oldValue) {
      if (!newValue || newValue !== oldValue) this.filtros.cities = null;
    },

    'filtros.cities': function onWatchQueryCities(value) {
      if (!value || !value.length) this.filtros.cities = null;
    },
  },
};
</script>

<style scoped>
.b2b-envio-customers--header {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.b2b-envio-customers--loading .b2b-loader {
  left: 50%;
  top: 50%;
  position: absolute;
  margin-left: -40px;
  margin-top: -20px;
}

.b2b-envio-customers-total-send {
  margin-left: 10px;
}
</style>
