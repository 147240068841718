var render = function render(_c,_vm){return _c('div',{ref:_vm.data.ref,staticClass:"grid__row",class:{
    [_vm.data.staticClass]: !!_vm.data.staticClass,
    'grid__row--wrap': _vm.props.wrap,
  },style:({
    overflow: _vm.props.overflow,
    overflowX: _vm.props.overflowX,
    overflowY: _vm.props.overflowY,
  })},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.data.domProps && _vm.data.domProps.textContent))]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }