import cpf from './cpf';
import cnpj from './cnpj';

export default (value) => {
  if (!value) return '';

  if (value.length === 11) {
    return cpf(value);
  }

  if (value.length === 14) {
    return cnpj(value);
  }

  return value;
};
