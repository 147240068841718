<template>
  <div data-app="true" class="app" :class="$_classes">
    <div class="app--wrap">
      <b2b-header v-show="config.header" :loading="loading" ref="header">
        <template #default="{ scrollTop }">
          <router-view name="header" :elevation="scrollTop" />
        </template>
      </b2b-header>

      <main class="content">
        <b2b-loading-view v-if="loading" />
        <b2b-transition-slide v-else>
          <router-view />
        </b2b-transition-slide>
      </main>

      <footer>
        <a href="https://tbsky.com.br/" target="_blank">
          <img src="/img/logo-tbs.png" alt="TBS Logo" style="margin-right: 4px" height="18px">
          {{ `${$t('labels.copyright')} ${new Date().getFullYear()} ${$t('labels.rights')}` }}
        </a>
      </footer>
    </div>

    <template v-if="!loading">
      <b2b-dialog-pwa-update v-model="update.show" @click:update="$_onClickUpdate" />

      <b2b-dialog-error v-model="dialog.show" :error="dialog.error" />

      <b2b-dialog-ok
        v-model="ok.show"
        :message="ok.message"
        :title="ok.title"
        :button="ok.button" />

      <b2b-dialog-stopwatch
        v-model="watch.show"
        :active="watch.show"
        :title="watch.title"
        :milliseconds="watch.milliseconds" />

      <b2b-dialog-confirm
        v-model="confirm.show"
        :message="confirm.message"
        @click:confirm="$_onClickConfirm" />

      <b2b-dialog-lido
        v-model="lido.show"
        :message="lido.message"
        :customerId="lido.customerId"
        :permitirLido="lido.permitirLido"
        @click:confirm="$_onClickLido" />

      <b2b-dialog-envio-customers
        v-if="envioCustomers.show"
        v-model="envioCustomers.show"
        :dataForm="envioCustomers.dataForm"
        @click:confirm="$_onClickEnvioCustomers" />

      <b2b-dialog-export-customers
        v-model="exportCustomers.show"
        :dataForm="exportCustomers.dataForm"
        @click:confirm="$_onClickExportCustomers" />

      <b2b-dialog-select-company v-model="companySelection" />

      <b2b-dialog-select-customer
        v-model="customerSelection"
        :persistent="customerPersistent"
      />
    </template>
  </div>
</template>

<script>
import ActionResolver from './mixins/action-resolver';
import EventHubResolver from './mixins/event-hub-resolver';
import MutationObserverResolver from './mixins/mutation-observer-resolver';

import B2bLoadingView from './views/Loading.vue';

import B2bHeader from './components/app/b2b-header.vue';
import B2bTransitionSlide from './components/core/transition/b2b-transition-slide.vue';

import B2bDialogPwaUpdate from './components/dialogs/b2b-dialog-pwa-update.vue';

import B2bDialogError from './components/dialogs/b2b-dialog-error.vue';
import B2bDialogOk from './components/dialogs/b2b-dialog-ok.vue';
import B2bDialogStopwatch from './components/dialogs/b2b-dialog-stopwatch.vue';
import B2bDialogConfirm from './components/dialogs/b2b-dialog-confirm.vue';
import B2bDialogLido from './components/dialogs/b2b-dialog-lido.vue';
import B2bDialogEnvioCustomers from './components/dialogs/b2b-dialog-envio-customers.vue';
import B2bDialogExportCustomers from './components/dialogs/b2b-dialog-export-customers.vue';

import B2bDialogSelectCompany from './components/dialogs/b2b-dialog-select-company.vue';
import B2bDialogSelectCustomer from './components/dialogs/b2b-dialog-select-customer.vue';

export default {
  name: 'App',

  components: {
    B2bHeader,
    B2bLoadingView,
    B2bTransitionSlide,
    B2bDialogPwaUpdate,
    B2bDialogError,
    B2bDialogOk,
    B2bDialogStopwatch,
    B2bDialogConfirm,
    B2bDialogSelectCompany,
    B2bDialogSelectCustomer,
    B2bDialogLido,
    B2bDialogEnvioCustomers,
    B2bDialogExportCustomers,
  },

  mixins: [
    ActionResolver(),

    EventHubResolver(),

    MutationObserverResolver(),
  ],

  data() {
    return {
      loading: true,
      companySelection: false,
      customerSelection: false,
      customerPersistent: false,

      config: {},

      dialog: {
        show: false,
        error: null,
      },

      ok: {
        show: false,
        message: null,
        title: null,
        button: true,
      },

      watch: {
        show: false,
        message: null,
        title: null,
        milliseconds: 0,
      },

      confirm: {
        show: false,
        message: null,
        handler: null,
      },

      lido: {
        show: false,
        message: null,
        customerId: null,
        permitirLido: false,
        handler: null,
      },

      envioCustomers: {
        show: false,
        dataForm: null,
        handler: null,
      },

      exportCustomers: {
        show: false,
        dataForm: null,
        handler: null,
      },

      update: {
        show: false,
      },
    };
  },

  computed: {
    $_classes() {
      if (this.loading) return null;

      return {
        'app--has-header': this.config.header,
      };
    },
  },

  methods: {
    $_onClickConfirm($event) {
      if (!this.confirm.handler) return;
      this.confirm.handler($event);
      this.confirm.handler = null;
    },

    $_onClickLido($event) {
      if (!this.lido.handler) return;
      this.lido.handler($event);
      this.lido.handler = null;
    },

    $_onClickEnvioCustomers($event) {
      if (!this.lido.handler) return;
      this.envioCustomers.handler($event);
      this.envioCustomers.handler = null;
    },

    $_onClickExportCustomers($event) {
      if (!this.lido.handler) return;
      this.exportCustomers.handler($event);
      this.exportCustomers.handler = null;
    },

    $_onClickUpdate() {
      if (!this.$_updateHandler) return;

      this.$_updateHandler();
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(route) {
        const configs = route.matched.map((record) => record.meta.config || {});
        const mergedConfigs = configs.reduce((merged, config) => Object.assign(merged, config), {});
        this.$set(this, 'config', mergedConfigs);
      },
    },
  },

  created() {
    this.$_actionResolver({
      action: 'setup',
      loading: 'loading',
      ignoreError: true,
      timer: 2000,
    });

    this.$_onHub('sw:updated', (handler) => {
      this.update.show = true;
      this.$_updateHandler = handler;
    });

    this.$_onHub('dialog:error', (error) => {
      this.dialog.error = error;
      this.dialog.show = true;
    });

    this.$_onHub('dialog:ok', (message, title, button = true) => {
      this.ok.message = message;
      this.ok.title = title;
      this.ok.show = true;
      this.ok.button = button;
    });

    this.$_onHub('dialog:watch', (title, milliseconds = 0) => {
      this.watch.title = title;
      this.watch.show = true;
      this.watch.milliseconds = milliseconds;
    });

    this.$_onHub('dialog:confirm', ({ message, handler }) => {
      this.confirm.message = message;
      this.confirm.handler = handler;
      this.confirm.show = true;
    });

    this.$_onHub('dialog:lido', ({ message, customerId, permitirLido, handler }) => {
      this.lido.message = message;
      this.lido.customerId = customerId;
      this.lido.permitirLido = permitirLido;
      this.lido.handler = handler;
      this.lido.show = true;
    });

    this.$_onHub('dialog:envioCustomers', ({ dataForm, handler }) => {
      this.envioCustomers.dataForm = dataForm;
      this.envioCustomers.handler = handler;
      this.envioCustomers.show = true;
    });

    this.$_onHub('dialog:exportCustomers', ({ dataForm, handler }) => {
      this.exportCustomers.dataForm = dataForm;
      this.exportCustomers.handler = handler;
      this.exportCustomers.show = true;
    });

    this.$_onHub('select:company', () => {
      this.companySelection = true;
    });

    this.$_onHub('select:customer', (persistent = false) => {
      this.customerSelection = true;
      this.customerPersistent = persistent;
    });

    let hidden;
    let visibilityChange;

    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    document.addEventListener(visibilityChange, () => {
      this.$hub.emit('window:visibility', !document[hidden]);
    });

    window.addEventListener('beforeinstallprompt', ($event) => {
      this.$store.commit('SET_SW_PROMPT', $event);
    });
  },
};
</script>

<style lang="scss">
@import './styles/break-points';

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

.app {
  width: 100%;
  min-height: 100%;
  position: absolute;

  display: flex;

  color: $colorMainPrimary;
  background-color: $colorBackgroundDefault;

  & > &--wrap {
    backface-visibility: hidden;

    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    min-height: 100%;
    max-width: 100%;

    position: relative;
  }

  main {
    z-index: 1;
  }

  footer {
    z-index: 1;

    > a {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.18);
    }

     @include breakpointMax('tablet') {
      font-size: 10px;
    }
  }

  .content {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    overscroll-behavior: contain;

    flex: 1 0 auto;

    transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }

  pre {
    font: inherit;
  }
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter {
  transform: translate3d(0, -20px, 0);
}

.v-leave-to {
  transform: translate3d(0, 20px, 0);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>
