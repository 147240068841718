<template>
  <b2b-list-item @click="$_onClick">
    <b2b-list-item-title v-text="$t('install-app')" />
  </b2b-list-item>
</template>

<script>
import B2bListItem from '../../core/list/b2b-list-item.vue';
import B2bListItemTitle from '../../core/list/b2b-list-item-title.vue';

export default {
  name: 'b2b-download-list-item',

  components: {
    B2bListItem,
    B2bListItemTitle,
  },

  methods: {
    $_onClick($event) {
      this.$emit('click', $event);
    },
  },
};
</script>
