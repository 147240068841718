<template>
  <b2b-input-layout v-bind="$_layoutBind" :focused="focused" class="b2b-input">
    <template #prepend>
      <slot name="prepend" />
    </template>

    <input
      v-model="$_model"
      v-bind="$_inputBind"
      ref="input"
      :multiple="multiple"
      :required="required"
      @click.stop="$_onClick"
      @focus="focused = true"
      @blur="focused = false"
      :maxlength="maxlength"
      :pattern="patternRegexInput"
      :oninvalid="$_setCustomValidity()"
      oninput="setCustomValidity('')"
      @keypress="$_inputKeyPress($event)"
    />

    <template>
      <slot name="hint" />
    </template>

    <div v-show="clearable && $_model" class="b2b-input__clear" @click.stop="$_onClickClear">
      <x-icon size="24" />
    </div>

    <template #append>
      <slot name="append" />
    </template>
  </b2b-input-layout>
</template>

<script>
import { XIcon } from 'vue-feather-icons';

import InputMixin from '../../../mixins/components/input-mixin';
import ModelMixin from '../../../mixins/base/model-mixin';

import B2bInputLayout from './b2b-input-layout.vue';

export default {
  name: 'b2b-input',

  mixins: [
    InputMixin(),
    ModelMixin(String),
  ],

  components: {
    B2bInputLayout,
    XIcon,
  },

  props: {
    clearable: Boolean,
    multiple: Boolean,
    textInputInvalid: {
      type: String,
      default: '',
    },
    patternRegexInput: {
      type: String,
      default: '.*',
    },
    maxlength: {
      type: Number,
      default: 255,
    },
  },

  data() {
    return {
      focused: false,
    };
  },

  methods: {
    focus() {
      this.$refs.input.focus();
    },

    $_onClick($event) {
      this.$emit('click', $event);
    },

    $_onClickClear($event) {
      this.$_model = '';
      this.$emit('click:clear', $event);
    },

    $_setCustomValidity() {
      return `setCustomValidity('${this.textInputInvalid}')`;
    },

    $_inputKeyPress($event) {
      this.$emit('keypress', $event);
    },
  },
};
</script>

<style lang="scss">
.b2b-input {
  &__clear {
    width: 48px;
    height: 48px;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  > input {
    &::placeholder {
      opacity: 0;
      color: transparent;
    }

    &[type=file] {
      padding-top: 12px;
    }

    &[type=color] {
      &::-webkit-color-swatch-wrapper {
        padding: 16px 0;
      }
      &::-webkit-color-swatch {
        border: none;
        border-radius: $inputHeight;
      }

      &::-moz-color-swatch {
        border: none;
        height: 16px;
        border-radius: $inputHeight;
      }
    }
  }
}
</style>
