<template functional>
  <transition name="fade" mode="out-in">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'b2b-transition-slide',
};
</script>

<style lang="scss">
  .slide-fade-enter {
    transform: translateX(-10px);
    opacity: 0;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all .5s;
  }

  .slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
