export default {
  path: '/help',
  name: 'Help',
  component: () => import(/* webpackChunkName: "help" */ '../../views/Help.vue'),
  meta: {
    requiresAuth: true,
    config: {
      header: true,
    },
  },
};
