<template functional>
  <component class="b2b-image"
    :is="props.cover || props.contain ? 'div' : 'img'"
    :src="props.cover || props.contain ? undefined : (props.src || '/img/image-404.jpg')"
    :alt="props.cover || props.contain ? undefined : props.alt"
    :class="{
      ...data.class,
      [data.staticClass]: !!data.staticClass,
      'b2b-image--cover': props.cover,
      'b2b-image--contain': props.contain,
      'b2b-image--outline': props.outline,
      'b2b-image--cursor': props.cursor,
    }"
    :style="{
      ...data.style,
      backgroundImage: !props.cover && !props.contain ? undefined
        : `url('${(props.src || '/img/image-404.jpg')}')`,
    }"
    v-on="listeners"
    v-bind="{
      ref: data.ref,
      ...data.attrs
    }"
  />
</template>

<script>
export default {
  name: 'b2b-image',

  props: {
    src: String,
    alt: String,
    cover: Boolean,
    contain: Boolean,
    outline: Boolean,
    cursor: Boolean,
  },
};
</script>

<style lang="scss">
.b2b-image--cursor {
  cursor: pointer;
}

.b2b-image {
  max-width: 100%;
  max-height: 100%;

  &--cover,
  &--contain {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &--cover {
    background-size: cover;
  }

  &--contain {
    background-size: contain;
  }

  &--outline {
    border-radius: 8px;
    border: 1px solid $colorMainSecondary;
  }
}
</style>
