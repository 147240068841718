export default [
  {
    path: '/profile',
    alias: '/settings',
    component: () => import(/* webpackChunkName: "profile" */ '../../views/Profile.vue'),
    meta: {
      requiresAuth: true,
      config: {
        header: true,
      },
    },
    children: [
      {
        path: '',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile-password" */ '../../views/ProfilePassword.vue'),
      },
      {
        path: 'company',
        name: 'Company',
        component: () => import(/* webpackChunkName: "profile-settings" */ '../../views/ProfileSettings.vue'),
        meta: {
          requiresAdmin: true,
          requiresMaster: false,
        },
      },
      {
        path: 'icon',
        name: 'Icon',
        component: () => import(/* webpackChunkName: "profile-icon" */ '../../views/ProfileIcon.vue'),
        meta: {
          requiresAdmin: true,
          requiresMaster: false,
        },
      },
      {
        path: 'email',
        name: 'Email',
        component: () => import(/* webpackChunkName: "profile-email" */ '../../views/ProfileEmail.vue'),
        meta: {
          requiresAdmin: true,
          requiresMaster: false,
        },
      },
      {
        path: 'send-invitation',
        name: 'SendInvitation',
        component: () => import(/* webpackChunkName: "profile-send-invitation" */ '../../views/ProfileSendInvitation.vue'),
        meta: {
          requiresAdmin: true,
          requiresMaster: false,
        },
      },
    ],
  },
];
