<template>
  <b2b-input-layout
    v-bind="$_layoutBind"
    class="b2b-select"
    :class="{
      'b2b-select--active': !disabled && isActive,
    }"
  >
    <template #prepend>
      <slot name="prepend" />
    </template>

    <select
      ref="input"
      v-model="$_model"
      v-bind="$_inputBind"
      :multiple="multiple"
      :disabled="disabled">
      <template v-for="item in items">
        <option :key="item.value || item" :value="item.value || item" v-text="item.text || item" />
      </template>
    </select>

    <div v-show="clearable && $_model" class="b2b-select__clear" @click.stop="$_onClickClear">
      <x-icon size="24" />
    </div>

    <div class="b2b-select__wraper" @click.stop.prevent="$_onClick">
      <div ref="options" class="b2b-select__options">
        <template v-for="item in $_unselectedItems">
          <div
            :key="item.value || item"
            :value="item.value || item"
            v-text="item.text || item"
            @click.stop="$_onClickItem(item)"
          />
        </template>
      </div>
    </div>

    <template #append>
      <chevron-down-icon class="b2b-input-layout__right-icon" />
    </template>
  </b2b-input-layout>
</template>

<script>
import { ChevronDownIcon, XIcon } from 'vue-feather-icons';

import InputMixin from '../../../mixins/components/input-mixin';
import ModelMixin from '../../../mixins/base/model-mixin';
import ClickOutsideMixin from '../../../mixins/base/click-outside-mixin';

import B2bInputLayout from './b2b-input-layout.vue';

export default {
  name: 'b2b-select',

  mixins: [
    ClickOutsideMixin({
      ref: 'options',
      prop: 'active',
    }),

    InputMixin(),

    ModelMixin(String),
  ],

  components: {
    B2bInputLayout,
    ChevronDownIcon,
    XIcon,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    multiple: Boolean,
    clearable: Boolean,
  },

  computed: {
    $_unselectedItems() {
      return this.items
        .filter((item) => this.value !== (item.value || item));
    },
  },

  methods: {
    focus() {
      this.$refs.input.focus();
    },

    $_onClick() {
      this.isActive = !this.isActive;
    },

    $_onClickClear() {
      this.$emit('input', null);
    },

    $_onClickItem(item) {
      this.$emit('input', item.value || item);
      this.isActive = false;
    },
  },
};
</script>

<style lang="scss">
$borderWidth: calc($inputHeight / 2);
$optionHeight: 32px;

.b2b-select {
  &--active {
    background: white;
    border-top-left-radius: $borderWidth;
    border-top-right-radius: $borderWidth;
    z-index: 4;

    .b2b-input-layout__border-left {
      border-bottom-left-radius: 0;
    }
    .b2b-input-layout__border-right {
      border-bottom-right-radius: 0;
    }
  }

  > select {
    appearance: none;
    padding-right: 42px;
    pointer-events: none;
  }

  &__clear {
    z-index: 3;
    height: 24px;
    cursor: pointer;
    align-self: center;
    margin-right: 48px;
  }

  &__wraper {
    z-index: 2;
    position: absolute;

    cursor: pointer;

    top: 0;
    left: 0;
    width: 100%;

    max-height: 0;
    overflow: hidden;

    padding-top: $inputHeight;
  }

  &__options {
    background-color: white;
    padding: calc($optionHeight / 4) 0;

    border-style: solid;
    border-width: 0 1px 1px;
    border-color: $colorMainPrimary;

    border-bottom-left-radius: $borderWidth;
    border-bottom-right-radius: $borderWidth;

    > div {
      padding: 0 $borderWidth;

      height: $optionHeight;
      line-height: $optionHeight;

      &:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }

  &__options {
    padding: 0;
    overflow-y: auto;
    max-height: $optionHeight * 5;
  }

  &--active &__wraper {
    border-radius: $borderWidth;
    max-height: $optionHeight * 15;

    box-shadow:
      0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}
</style>
