var render = function render(_c,_vm){return _c('div',_vm._b({staticClass:"b2b-container",class:{
  [_vm.data.staticClass]: _vm.data.staticClass,
  'b2b-container--row': _vm.props.row,
  'b2b-container--column': _vm.props.column,
  'b2b-container--align-center': _vm.props.alignCenter,
  'b2b-container--justify-center': _vm.props.justifyCenter,
}},'div',_vm.data.attrs,false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }