export default function EventHuvResolver() {
  const events = [];

  return {
    methods: {
      $_onHub(event, handler) {
        this.$hub.on(event, handler);
        events.push(event);
      },

      $_offHub(event) {
        this.$hub.off(event);
      },
    },

    beforeDestroy() {
      events.forEach(this.$_offHub);
    },
  };
}
