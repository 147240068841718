<template>
  <table
  class="b2b-table"
  :class="{
    'b2b-table--clickable': clickable,
  }">
    <thead v-if="!cards">
      <tr>
        <template v-for="header in headers">
          <th
            :key="header.value"
            :width="header.width"
            :class="header.align
              ? `text--${header.align}`
              : [Number, Date].includes(header.type) ? 'text--right' : 'text--left'
            "
          >
            <slot :name="`headers:${header.value}`" v-bind="{ header }">
              {{ header.text || header.value }}
            </slot>
          </th>
        </template>
      </tr>
    </thead>
    <template v-for="item in items">
      <component
        v-bind="{ item, headers }"
        :key="item[itemKey]"
        :is="$_rowComponent"
        @click="$_onClickItem(item)"
      >
        <template v-for="header in headers" v-slot:[`items:${header.value}`]="bind">
          <slot :name="`items:${header.value}`" v-bind="bind" />
        </template>

        <template #card>
          <slot name="item:card" v-bind="{ item, headers }" />
        </template>

        <template #extra-rows>
          <slot name="extra-rows" v-bind="{ item, headers }" />
        </template>
      </component>
    </template>
  </table>
</template>

<script>
import B2bTableRow from './b2b-table-row.vue';
import B2bTableCardRow from './b2b-table-card-row.vue';

export default {
  name: 'b2b-table',
  components: {
    B2bTableRow,
    B2bTableCardRow,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: Array,
    itemKey: {
      type: String,
      default: 'id',
    },
    clickable: Boolean,
    cards: Boolean,
  },
  computed: {
    $_rowComponent() {
      return this.cards ? B2bTableCardRow : B2bTableRow;
    },
  },
  methods: {
    $_onClickItem(item) {
      this.$emit('click:item', item);
    },
  },
};
</script>

<style lang="scss">
$colorPrimary: var(--main-color--primary);
$colorPrimaryText: var(--main-color--primary-text);

.b2b-table {
  width: 100%;
  border-collapse: collapse;

  & th {
    height: 48px;
    padding: 0 16px;

    top: 0;
    z-index: 1;
    position: sticky;
    background-color: white;

    &::before {
      content: '';

      left: 0;
      bottom: 0;
      width: 100%;
      position: absolute;

      border-bottom: thin solid rgba(0,0,0,.12);
    }
  }

  &--clickable {
    > tbody > tr:first-child:hover {
      cursor: pointer;
    }
  }
}
</style>
