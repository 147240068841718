<template>
  <b2b-overlay v-model="isActive" role="dialog" persistent>
    <b2b-card width="300" fill-height>
      <b2b-card-title v-text="$t('resources.pwa.update-available')" />

      <b2b-card-text class="mt--2" v-text="$t('resources.pwa.update-now')" fill-height />

      <b2b-card-actions>
        <b2b-button @click.stop="$_onClickUpdate" v-text="$t('resources.pwa.update')" dense />
      </b2b-card-actions>
    </b2b-card>
  </b2b-overlay>
</template>

<script>
import ActivableMixin from '../../mixins/base/activable-mixin';

import B2bOverlay from '../core/b2b-overlay.vue';
import B2bCard from '../core/card/b2b-card.vue';
import B2bCardTitle from '../core/card/b2b-card-title.vue';
import B2bCardText from '../core/card/b2b-card-text.vue';
import B2bCardActions from '../core/card/b2b-card-actions.vue';
import B2bButton from '../core/buttons/b2b-button.vue';

export default {
  name: 'b2b-dialog-pwa-update',

  components: {
    B2bOverlay,
    B2bCard,
    B2bCardTitle,
    B2bCardText,
    B2bCardActions,
    B2bButton,
  },

  mixins: [
    ActivableMixin(),
  ],

  methods: {
    $_onClickUpdate() {
      this.$emit('click:update');
    },
  },
};
</script>
