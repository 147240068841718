import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';
import { hasStandalone } from '../utils/user-agent';

const contextRoutes = require.context('./routes', true, /\.js$/);

if (hasStandalone() && !window.location.hash) {
  window.location = window.location.href.replace(window.location.host, `${window.location.host}/#`);
} else if (!hasStandalone() && window.location.hash) {
  window.location = window.location.origin + window.location.hash.replace(/^#/, '');
}

Vue.use(VueRouter);

const router = new VueRouter({
  mode: hasStandalone() ? 'hash' : 'history',
  routes: [
    ...contextRoutes.keys().reduce((routes, routeKey) => {
      const route = contextRoutes(routeKey).default;
      if (Array.isArray(route)) return [...routes, ...route];
      return [...routes, route];
    }, []),
    {
      path: '*',
      redirect() {
        if (!store.getters['session/user']) {
          return { name: 'Login' };
        }

        if (store.getters['session/isCustomer']) {
          return { name: 'Main' };
        }

        if (store.getters['session/isAdmin']) {
          return { name: 'CustomersIndex' };
        }

        return { name: 'CompaniesIndex' };
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authenticated = await store.dispatch('session/hasAccess');

  const requiresUnauth = to.matched.some((record) => record.meta.requiresUnauth);
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresCustomer = to.matched.some((record) => record.meta.requiresCustomer);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const requiresMaster = to.matched.some((record) => record.meta.requiresMaster);

  const requiresAuthenticated = requiresAuth || requiresAdmin || requiresCustomer || false;

  if (!authenticated && requiresAuthenticated) {
    router.push({ name: 'Login', query: { redirect: to.fullPath } });
  } else if (authenticated && requiresUnauth) {
    router.push({ path: '/$redirect' });
  } else if (requiresCustomer && !store.getters['session/isCustomer']) {
    router.push({ path: '/$redirect' });
  } else if (requiresAdmin && !store.getters['session/isAdmin']) {
    router.push({ path: '/$redirect' });
  } else if (requiresMaster && !store.getters['session/isMaster']) {
    router.push({ path: '/$redirect' });
  } else {
    next();
  }
});

export default router;
