import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('users', ({ Api, resource }) => ({
  actions: {
    async profile() {
      const { data } = await Api.get(`${resource}/profile`);
      return data;
    },

    async companies() {
      const { data } = await Api.get(`${resource}/companies`);
      return data;
    },

    async changePassword(_, { ...payload }) {
      const { data } = await Api.put(`${resource}/changePassword`, payload);
      return data;
    },

    async forgotPassword(_, { ...payload }) {
      const { data } = await Api.post(`${resource}/forgotPassword`, payload);
      return data;
    },

    async resetPassword(_, { resetToken, ...payload }) {
      const { data } = await Api.put(`${resource}/resetPassword`, {
        email: payload.email,
        newPassword: payload.password,
      }, {
        headers: {
          Authorization: `Bearer ${resetToken}`,
        },
      });

      return data;
    },

    async uploadAvatar(_, { form }) {
      const { data } = await Api.post(`${resource}/upload`, form, {
        headers: {
          'content-type': `multipart/form-data; boundary=${form.boundary}`,
        },
      });

      return data;
    },
  },
}));
