<template functional>
  <tbody class="b2b-table__card-row">
    <tr @click.stop="listeners.click">
      <td :colspan="props.headers.length">
        <slot name="card">
          <div class="b2b-table__card-row__card">
            <template v-for="header in props.headers">
              <div :key="header.value">
                <b>
                  <slot :name="`headers:${header.value}`" v-bind="{ header }">
                    {{ header.text || header.value }}
                  </slot>
                </b>
                <span>
                  <slot :name="`items:${header.value}`" v-bind="{ item: props.item, header }">
                    {{ props.item[header.value] }}
                  </slot>
                </span>
              </div>
            </template>
          </div>
        </slot>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  name: 'b2b-table-card-row',
  props: {
    item: Object,
    headers: Array,
  },
};
</script>

<style lang="scss">
.b2b-table__card-row {
  &__card {
    padding: 16px;
    margin-bottom: 16px;

    box-shadow: $cardBoxShadow;
    border-radius: $cardBorderRadius;
    background-color: $cardBackground;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > span {
        text-align: right;
      }
    }
  }
}
</style>
