import Api from '../../services/api';
import IdIsRequired from '../../utils/IdIsRequired';

export default function ResourcePattern(resource, injection) {
  const inject = typeof injection === 'function' ? injection({ resource, Api }) : {};

  return {
    namespaced: true,

    state: {
      ...inject.state,
    },

    getters: {
      ...inject.getters,
    },

    actions: {
      async $index(_, params) {
        const { data, headers } = await Api.get(`/${resource}`, { params });
        return {
          items: data,
          pages: headers['x-total-page'],
          count: headers['x-total-count'],
        };
      },

      index({ dispatch }, payload) {
        return dispatch('$index', payload);
      },

      async $show(_, { id }) {
        if (!id) throw new IdIsRequired('Id is required');

        const { data } = await Api.get(`/${resource}/${encodeURIComponent(id)}`);
        return data;
      },

      show({ dispatch }, payload) {
        return dispatch('$show', payload);
      },

      async $create(_, payload) {
        const { data } = await Api.post(`/${resource}`, payload);
        return data;
      },

      create({ dispatch }, payload) {
        return dispatch('$create', payload);
      },

      async $update(_, { id, ...payload }) {
        if (!id) throw new IdIsRequired('Id is required');

        const { data } = await Api.put(`/${resource}/${encodeURIComponent(id)}`, payload);
        return data;
      },

      update({ dispatch }, payload) {
        return dispatch('$update', payload);
      },

      async $createOrUpdate({ dispatch }, { id, ...payload }) {
        if (!id) return dispatch('create', payload);
        return dispatch('update', { id, ...payload });
      },

      createOrUpdate({ dispatch }, payload) {
        return dispatch('$createOrUpdate', payload);
      },

      async $destroy(_, { id }) {
        if (!id) throw new IdIsRequired('Id is required');

        await Api.delete(`/${resource}/${encodeURIComponent(id)}`);
        return null;
      },

      destroy({ dispatch }, payload) {
        return dispatch('$destroy', payload);
      },

      ...inject.actions,
    },

    mutations: {
      ...inject.mutations,
    },
  };
}
