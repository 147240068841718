<template functional>
  <div class="b2b-card__text"
  :class="{
    [data.staticClass]: !!data.staticClass,
    'b2b-card__text--fill-height': props.fillHeight,
    'b2b-card__text--row': props.row,
    'b2b-card__text--scrollable': props.scrollable,
  }">
    <slot>{{ data.domProps && data.domProps.textContent }}</slot>
  </div>
</template>

<script>
export default {
  name: 'b2b-card-text',
  props: {
    row: Boolean,
    fillHeight: Boolean,
    scrollable: Boolean,
  },
};
</script>

<style lang="scss">
.b2b-card__title + .b2b-card__text {
  padding-top: 0;
  white-space: break-spaces;
}

.b2b-card__text {
  padding: 8px 16px;

  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;

  &--row {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &--fill-height {
    flex: 1;
    overflow: hidden;
  }

  &--scrollable {
    padding: 0;
    display: block;
    overflow-y: auto;
    overscroll-behavior: contain;

    border-top: 1px solid $colorMainSecondary;
    border-bottom: 1px solid $colorMainSecondary;
  }
}
</style>
