import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('bucket', ({ Api, resource }) => ({
  actions: {
    async getURL(context, url) {
      if (!url) return null;
      return `${Api.baseURL}/${resource}?url=${encodeURIComponent(url)}`;
    },
  },
}));
