<template>
  <b2b-overlay v-model="isActive" :persistent="persistent" role="dialog">
    <b2b-card width="512" fill-height>
      <b2b-list-customers @click:item="$_onClickItem" />
    </b2b-card>
  </b2b-overlay>
</template>

<script>
import ActivableMixin from '../../mixins/base/activable-mixin';
import ActionResolver from '../../mixins/action-resolver';

import B2bOverlay from '../core/b2b-overlay.vue';
import B2bCard from '../core/card/b2b-card.vue';
import B2bListCustomers from '../lists/b2b-list-customers.vue';

export default {
  name: 'b2b-dialog-select-customer',

  mixins: [
    ActivableMixin(),
    ActionResolver(),
  ],

  components: {
    B2bOverlay,
    B2bCard,
    B2bListCustomers,
  },

  props: {
    persistent: Boolean,
  },

  computed: {
    $_currentCustomer() {
      return this.$store.getters['session/customer'];
    },
  },

  methods: {
    $_reload() {
      window.location.href = window.location.origin;
    },
    $_onClickItem(customer) {
      if (customer.userCustomerId === this.$_currentCustomer?.userCustomerId) {
        this.isActive = false;
        return;
      }

      this.$_actionResolver({
        action: 'session/setCustomer',
        params: customer.userCustomerId,
      })
        .then(() => {
          this.$_reload();
        })
        .finally(() => {
          this.isActive = false;
        });
    },
  },
};
</script>
