<template>
  <button
    v-on="$_listeners"
    class="b2b-button"
    :is="to ? 'router-link' : 'button'"
    :to="to"
    :type="!to && type"
    :disabled="$_disabled"
    :class="{
      'b2b-button--block': block,
      'b2b-button--dense': dense,
      'b2b-button--loading': loading,
      'b2b-button--flat': flat,
    }">
    <b2b-loading v-if="loading" />
    <slot v-else />
  </button>
</template>

<script>
import ButtonMixin from '../../../mixins/components/button-mixin';

import B2bLoading from '../b2b-loading.vue';

export default {
  name: 'b2b-button',

  components: {
    B2bLoading,
  },

  mixins: [
    ButtonMixin(),
  ],

  props: {
    block: Boolean,
    dense: Boolean,
    flat: Boolean,
  },
};
</script>

<style lang="scss">
$colorPrimary: var(--main-color--primary);
$colorPrimaryText: var(--main-color--primary-text);

.b2b-button {
  margin: 8px;

  height: $buttonSize;
  min-height: $buttonSize;
  padding: 0 calc($buttonSize / 2);

  flex: 0;
  align-items: center;
  display: inline-flex;
  justify-content: center;

  background-color: transparent;

  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  color: $colorPrimary;
  text-transform: uppercase;

  border-radius: $buttonSize;
  border: 1px solid $colorPrimary;

  transition: all 0.2s;

  &:hover:not(:disabled) {
    color: $colorPrimaryText;
    box-shadow: $boxShadowDefault;
    background-color: $colorPrimary;
  }

  &:active:not(:disabled) {
    box-shadow: none !important;
  }

  &:disabled:not(&--loading) {
    opacity: 0.5;
  }

  &--block {
    flex: 1;
    width: 100%;
  }

  &--dense {
    height: 32px;
    min-height: 32px;

    font-size: 14px;
  }

  &--flat {
    border: none;
  }
}
</style>
