import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('clientesUsuarios', ({ Api, resource }) => ({
  actions: {
    async getPrimaryLogin(_, { id }) {
      const { data } = await Api.get(`${resource}/${id}/firstlogin`);
      return data;
    },
  },
}));
