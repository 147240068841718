<template>
  <b2b-input
    v-model="$_model"
    v-bind="$__bind"
    type="date"
    ref="input"
    class="b2b-input-date-picker"
    :class="{
      'b2b-input-date-picker--hide-placeholder': !$_model,
    }"
    :disabled="disabled"
    @keypress="$_keyPressDatePicker"
    clearable
  >
    <template #append v-if="!$_model">
      <calendar-icon class="b2b-input-date-picker__icon" />
    </template>
  </b2b-input>
</template>

<script>
import { CalendarIcon } from 'vue-feather-icons';

import { toAmericanString } from '../../../utils/format-date';

import InputMixin from '../../../mixins/components/input-mixin';

import B2bInput from './b2b-input.vue';

export default {
  name: 'b2b-input-date-picker',

  components: {
    B2bInput,
    CalendarIcon,
  },

  mixins: [
    InputMixin('date', true),
  ],

  props: {
    value: [String, Date],
    max: [String, Date],
    min: [String, Date],
  },

  methods: {
    $_keyPressDatePicker($event) {
      $event.preventDefault();
    },
  },

  computed: {
    $_model: {
      get() {
        if (this.value instanceof Date) {
          return toAmericanString(this.value);
        }

        return this.value;
      },

      set(value) {
        if (this.value instanceof Date) {
          this.$emit('input', new Date(value));
        } else {
          this.$emit('input', value);
        }
      },
    },

    $_min() {
      return this.min ? toAmericanString(this.min) : null;
    },

    $_max() {
      return this.max ? toAmericanString(this.max) : null;
    },

    $__bind() {
      return {
        ...this.$_bind,
        min: this.$_min,
        max: this.$_max,
      };
    },
  },
};
</script>

<style lang="scss">
.b2b-input-date-picker {
  position: relative;

  &--hide-placeholder {
    input:not(:focus)::-webkit-datetime-edit {
      color: transparent;
    }
  }

  input {
    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      width: 48px;
      height: 48px;
      max-width: 48px;
      max-height: 48px;
      position: absolute;

      opacity: 0;
      cursor: pointer;

      border-radius: 24px;
    }
  }

  &__icon {
    top: 12px;
    right: 12px;
    position: absolute;

    cursor: pointer;
    pointer-events: none;
  }
}
</style>
