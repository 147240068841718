import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('invitations', ({ resource, Api }) => ({
  actions: {
    async register(_, { id, ...payload }) {
      const { data } = await Api.post(`/${resource}/${encodeURIComponent(id)}/accept`, payload);
      return data;
    },
    async sendInviteAdmin(_, { tenant, ...payload }) {
      const { data } = await Api.post(`/${resource}`, payload, {
        headers: {
          'x-tenant': tenant,
        },
      });
      return data;
    },
    async getInviteTemplate(_, { id }) {
      const { data } = await Api.get(`${resource}/${id}/template`);
      return data;
    },
    async postConfigInvite(_, params) {
      const { data } = await Api.post(`${resource}/config`, params);
      return data;
    },
    async updateConfigInvite(_, { id, form }) {
      const { data } = await Api.put(`${resource}/${id}/config`, form);
      return data;
    },
    async deleteConfigInvite(_, { id }) {
      const { data } = await Api.delete(`${resource}/${id}/config`);
      return data;
    },
    async getSmtp(_, { id }) {
      const { data } = await Api.get(`${resource}/${id}/smtp`);
      return data;
    },
    async updateSmtp(_, { id, form }) {
      const { data } = await Api.put(`${resource}/${id}/smtp`, form);
      return data;
    },
    async flagSmtp(_, { id, form }) {
      const { data } = await Api.patch(`${resource}/${id}/smtp`, form);
      return data;
    },
  },
}));
