<template>
  <div class="b2b-data-table__pagination">
    <span v-text="$_info" />
    <b2b-pagination v-bind="$props" v-on="$listeners" />
  </div>
</template>

<script>
import ModelMixin from '../../../mixins/base/model-mixin';

import B2bPagination from '../b2b-pagination.vue';

export default {
  name: 'b2b-data-table-pagination',
  mixins: [
    ModelMixin([String, Number]),
  ],
  components: {
    B2bPagination,
  },
  props: {
    value: [String, Number],
    pages: [String, Number],
    count: [String, Number],
    total: [String, Number],
    disabled: Boolean,
    perPage: {
      type: Number,
      default: 24,
    },
  },
  computed: {
    $_info() {
      const startNumber = ((this.value - 1) * this.perPage);
      return this.$t('labels.pagination', [
        startNumber + 1,
        startNumber + this.count,
        this.total,
      ]);
    },
  },
};
</script>

<style lang="scss">
@import '../../../styles/break-points.scss';

$size: 42px;

.b2b-data-table__pagination {
  padding: 8px 16px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  > div {
    justify-content: flex-end;

    button {
      width: $size;
      height: $size;
      min-width: $size;
      border-radius: calc($size / 2);
    }
  }

  @include breakpointMin('mobile') {
    flex-direction: column;

    > span {
      margin-bottom: 8px;
    }
  }
}
</style>
