<template functional>
  <div
    class="grid__row"
    :ref="data.ref"
    :class="{
      [data.staticClass]: !!data.staticClass,
      'grid__row--wrap': props.wrap,
    }"
    :style="{
      overflow: props.overflow,
      overflowX: props.overflowX,
      overflowY: props.overflowY,
    }"
  >
    <slot>{{ data.domProps && data.domProps.textContent }}</slot>
  </div>
</template>

<script>
export default {
  name: 'b2b-grid-row',
  props: {
    wrap: Boolean,
    overflow: String,
    overflowX: String,
    overflowY: String,
  },
};
</script>

<style lang="scss">
.grid__row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;

  &--wrap {
    flex-wrap: wrap;
  }
}
</style>
