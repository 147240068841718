export default function ActivableMixin(prop = 'value') {
  const event = prop === 'value' ? 'input' : `update:${prop}`;

  return {
    props: {
      [prop]: Boolean,
    },
    data() {
      return {
        isActive: false,
      };
    },
    watch: {
      [prop]: {
        immediate: true,
        handler(active) {
          this.isActive = active;
        },
      },
      isActive(isActive) {
        this.$emit(event, isActive);
      },
    },
  };
}
