<template>
  <b2b-overlay classes="b2b-dialog-ok" v-model="isActive" role="dialog">
    <b2b-card width="300" fill-height>
      <b2b-card-title v-text="$_title" row />

      <b2b-card-text class="mt--2" v-text="$_message" fill-height />

      <b2b-card-actions>
        <b2b-button
          v-if="button"
          @click.stop="$_onClickOk"
          v-text="$t('labels.ok')" dense />
      </b2b-card-actions>
    </b2b-card>
  </b2b-overlay>
</template>

<script>
import ActivableMixin from '../../mixins/base/activable-mixin';

import B2bOverlay from '../core/b2b-overlay.vue';
import B2bCard from '../core/card/b2b-card.vue';
import B2bCardTitle from '../core/card/b2b-card-title.vue';
import B2bCardText from '../core/card/b2b-card-text.vue';
import B2bCardActions from '../core/card/b2b-card-actions.vue';
import B2bButton from '../core/buttons/b2b-button.vue';

export default {
  name: 'b2b-dialog-ok',
  mixins: [
    ActivableMixin(),
  ],
  components: {
    B2bOverlay,
    B2bCard,
    B2bCardTitle,
    B2bCardText,
    B2bCardActions,
    B2bButton,
  },
  props: {
    message: String,
    title: String,
    button: Boolean,
  },
  computed: {
    $_title() {
      return this.title || this.$t('ok.generic-title');
    },
    $_message() {
      return this.message || this.$t('ok.generic-message');
    },
  },
  methods: {
    $_onClickOk() {
      this.isActive = false;
    },
  },
};
</script>

<style lang="scss">
.b2b-dialog-ok {
  z-index: 102;
}
</style>
