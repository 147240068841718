import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('agrupamentos', ({ resource, Api }) => ({
  actions: {
    async letters(_, params) {
      const { data } = await Api.get(`${resource}/letras`, { params });
      const items = data
        .map(({ letra }) => String(letra).toUpperCase())
        .filter((letra) => !/\s/.test(letra));

      return { items };
    },
  },
}));
