<template functional>
  <div class="b2b-divider"
  :class="{
    [data.staticClass]: !!data.staticClass,
    'b2b-divider--no-margin': props.noMargin,
  }">
    <span v-if="(data.domProps && data.domProps.textContent)">
      <slot>{{ data.domProps && data.domProps.textContent }}</slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'b2b-divider',
  props: {
    noMargin: Boolean,
  },
};
</script>

<style lang="scss">
.b2b-divider {
  display: flex;
  margin: 16px 0;
  min-width: 100%;

  align-items: center;
  flex-direction: row;
  justify-content: center;

  &::after,
  &::before {
    content: '';

    height: 1px;
    display: block;

    flex: 1;

    background-color: $colorMainSecondary;
  }

  &--no-margin {
    margin: 0;
  }

  > span {
    padding: 0 8px;
    border: 1px solid $colorMainSecondary;
    border-radius: 50px;
  }
}
</style>
