import XLSX from 'xlsx';

const createHead = (headers) => {
  const thead = document.createElement('thead');

  const trow = document.createElement('tr');
  thead.appendChild(trow);

  Object.values(headers).forEach((value) => {
    const theader = document.createElement('th');
    theader.innerText = value;

    trow.appendChild(theader);
  });

  return thead;
};

const createRow = (item, headers) => {
  const trow = document.createElement('tr');

  Object.keys(headers).forEach((key) => {
    const td = document.createElement('td');
    if (item[key] && typeof item[key] === 'number') {
      td.innerText = item[key].toFixed(2).replace('.', ',').toString();
    } else {
      td.innerText = item[key];
    }

    trow.appendChild(td);
  });

  return trow;
};

const convertBinaryToOctet = (binary) => {
  const buf = new ArrayBuffer(binary.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < binary.length; i++) view[i] = binary.charCodeAt(i) & 0xFF; // eslint-disable-line
  return buf;
};

export const downloadFile = (filename, data, type = 'text/plain') => {
  const blob = new Blob([data], { type });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const element = window.document.createElement('a');
    element.href = window.URL.createObjectURL(blob);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
};

export const jsonToExcel = (filename, json, headers) => {
  if (!json?.length) return null;
  const date = new Date().toLocaleString().replace(/\s/g, '_');

  const table = document.createElement('table');

  const mapHeaders = headers ?? Object.keys(json[0])
    .reduce((previous, key) => ({ ...previous, [key]: key }), {});

  const thead = createHead(mapHeaders);
  table.appendChild(thead);

  const tbody = document.createElement('tbody');
  table.appendChild(tbody);

  json.forEach((item) => {
    const trow = createRow(item, mapHeaders);
    tbody.appendChild(trow);
  });

  const wb = XLSX.utils.table_to_book(table, { sheet: 'Planilha 1', raw: true });

  const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

  return downloadFile(`${filename}-${date}.xlsx`, convertBinaryToOctet(wbout), { type: 'application/octet-stream' });
};

export const exportExcelXlsx = async (jsonArray, headers, filename) => {
  if (!jsonArray?.length) return null;

  const date = new Date().toLocaleString().replace(/\s/g, '_');
  const headersEntries = Object.entries(headers);
  const jsonSheet = jsonArray.map((json) => headersEntries.reduce((obj, [key, attr]) => ({
    ...obj,
    [attr]: json[key],
  }), {}));

  const newBook = XLSX.utils.book_new();
  const newSheet = XLSX.utils.json_to_sheet(jsonSheet);
  XLSX.utils.book_append_sheet(newBook, newSheet, 'Planilha 1');

  return XLSX.writeFile(newBook, `${filename}-${date}.xlsx`);
};
