<template>
  <component
    :is="$_component"
    class="b2b-data-table"
    :class="{
      'b2b-data-table--fill-height': fillHeight,
    }"
  >
    <div v-if="$slots.header" class="b2b-data-table__header">
      <slot name="header" />
    </div>

    <!-- <div :class="$_isMobile ? null : 'b2b-data-table__wrap'"> -->
    <div :class="{
      'b2b-data-table__wrap': $_isMobile,
      'b2b-data-table__wrap-overflow': activeScroll,
    }">
      <b2b-table v-bind="$_tableProps"
        ref="table"
        :items="items"
        :item-key="itemKey"
        :cards="$_isMobile"
        :clickable="!!$listeners['click:item']"
        @click:item="$_onClickItem"
      >
        <template v-for="header in headers" v-slot:[`headers:${header.value}`]="bind">
          <slot :name="`headers:${header.value}`" v-bind="bind" />
        </template>

        <template v-for="header in headers" v-slot:[`items:${header.value}`]="bind">
          <slot :name="`items:${header.value}`" v-bind="bind" />
        </template>

        <template #items:selectable="{ item }">
          <b2b-checkbox v-model="$_model" :value="item[itemKey]" />
        </template>

        <template #item:card="bind">
          <slot name="item:card" v-bind="bind" />
        </template>

        <template v-if="expandable" #extra-rows="{ item }">
          <b2b-transition-fade>
            <tr v-if="expanded === item[itemKey]">
              <td :colspan="headers.length">
                <slot name="expandable" v-bind="{ item, headers }" />
              </td>
            </tr>
          </b2b-transition-fade>
        </template>
      </b2b-table>
    </div>

    <slot name="footer" />
  </component>
</template>

<script>
import ModelMixin from '../../../mixins/base/model-mixin';
import BreakpointsMixin from '../../../mixins/base/breakpoints-mixin';

import B2bCard from '../card/b2b-card.vue';
import B2bTable from './b2b-table.vue';
import B2bCheckbox from '../form/b2b-checkbox.vue';
import B2bTransitionFade from '../transition/b2b-transition-fade.vue';

export default {
  name: 'b2b-data-table',

  components: {
    B2bCard,
    B2bTable,
    B2bCheckbox,
    B2bTransitionFade,
  },

  mixins: [
    ModelMixin(Array),
    BreakpointsMixin(),
  ],

  props: {
    id: String,
    itemKey: {
      type: String,
      default: 'id',
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    expandable: Boolean,
    noCard: Boolean,
    selectable: Boolean,
    fillHeight: Boolean,
    activeScroll: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      pages: 1,
      loading: false,
      expanded: null,
    };
  },

  computed: {
    $_component() {
      return this.$_isMobile || this.noCard ? 'div' : 'b2b-card';
    },

    $_tableProps() {
      const headers = [...this.headers];
      if (this.selectable) {
        headers.unshift({
          text: ' ',
          value: 'selectable',
          align: 'center',
          type: Boolean,
          width: 60,
        });
      }

      return {
        id: this.id,
        itemKey: this.itemKey,
        items: this.items,
        headers,
      };
    },
  },

  methods: {
    $_onClickItem(item) {
      this.$emit('click:item', item);

      if (this.expandable && item) {
        this.expanded = this.expanded !== item[this.itemKey] ? item[this.itemKey] : null;
        this.$emit('expanded', this.expanded ? item : null);
      }
    },
  },
};
</script>

<style lang="scss">
.b2b-data-table {
  &__header {
    padding: 8px;
  }

  &.b2b-card {
    background-color: $colorBackgroundCard;
  }

  &.b2b-card &__header {
    padding: 8px 16px;
  }

  &--fill-height {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &--fill-height &__wrap {
    flex: 1;
    overflow-y: auto;
  }

  // TODO - Atualmente o recurso buga junto ao tooltip (ultima linha)
  // &__wrap {
  //   overflow-x: auto;
  // }
}

.b2b-data-table__wrap-overflow {
  height: 47vh !important;
  overflow-y: auto !important;
}
</style>
