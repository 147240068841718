export default [
  {
    path: '/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "products" */ '../../views/Products.vue'),
    meta: {
      requiresAdmin: true,
      config: {
        header: true,
      },
    },
  },
  {
    path: '/products/:productId',
    name: 'ProductsProduct',
    component: () => import(/* webpackChunkName: "products-id" */ '../../views/ProductsProduct.vue'),
    meta: {
      requiresAdmin: true,
      config: {
        header: true,
        back: { name: 'Products' },
      },
    },
  },
];
