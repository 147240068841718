export default [
  {
    path: '/',
    name: 'Main',
    components: {
      default: () => import(/* webpackChunkName: "catalog" */ '../../views/Main.vue'),
      header: () => import(/* webpackChunkName: "catalog" */ '../../views/MainHeader.vue'),
    },
    meta: {
      requiresCustomer: true,
      config: {
        header: true,
      },
    },
  },
  {
    path: '/catalog/:productId',
    name: 'Main.Product',
    component: () => import(/* webpackChunkName: "catalog-product" */ '../../views/CatalogProduct.vue'),
    meta: {
      requiresCustomer: true,
      config: {
        header: true,
        back: { name: 'Main' },
      },
    },
  },
  {
    path: '/announcement/:bannerId',
    name: 'Main.Banner',
    component: () => import(/* webpackChunkName: "catalog-banner" */ '../../views/CatalogBanner.vue'),
    meta: {
      requiresCustomer: true,
      config: {
        header: true,
        back: { name: 'Main' },
      },
    },
  },
];
