import UnauthorizedError from '../../utils/UnauthorizedError';

import Api, { setApiAccessToken, setApiTenant, setApiUserCustomer } from '../../services/api';
import { getFromStorage, setInStorage, clearStorage } from '../../services/storage';
import { setPrimaryColor } from '../../utils/css-variables';

const KEY_ACCESS_TOKEN = 'session:access-token';
const KEY_COMPANY = 'session:company';
const KEY_CUSTOMER = 'session:customer';

let defaultPrimaryColor = process.env.VUE_APP_CSS_PRIMARY_COLOR;

let setupResolve = null;
const setup = new Promise((resolve) => {
  setupResolve = resolve;
});

export default {
  namespaced: true,

  state: {
    user: null,
    company: null,
    customer: null,
  },

  getters: {
    user: (state) => state.user,
    company: (state) => state.company,
    customer: (state) => state.customer,
    isMaster: (state) => !!state.user?.master,
    isAdmin: (state) => /admin/.test(state.company?.roles),
    isCustomer: (state) => /customer/.test(state.company?.roles),
  },

  actions: {
    async setup({ commit, dispatch }) {
      const config = await dispatch('companies/configs', null, { root: true });
      defaultPrimaryColor = config?.primaryColor ?? defaultPrimaryColor;

      const accessToken = await getFromStorage(KEY_ACCESS_TOKEN);
      if (!accessToken) {
        commit('CLEAR_SESSION');
      } else {
        commit('SET_TOKEN', accessToken);

        try {
          await dispatch('loadUser');
        } catch (err) {
          setupResolve();
          throw err;
        }

        const company = await getFromStorage(KEY_COMPANY);
        if (!company) {
          commit('SET_CUSTOMER', null);
        } else {
          commit('SET_COMPANY', company);

          const customer = await getFromStorage(KEY_CUSTOMER);
          if (customer) {
            commit('SET_CUSTOMER', customer);
          }
        }
      }

      setupResolve();
    },

    async hasAccess({ getters }) {
      await setup;

      if (getters.isMaster) return true;
      if (getters.isAdmin && getters.company) return true;
      return getters.isCustomer && getters.customer;
    },
    async login({ commit, dispatch }, payload) {
      const { data } = await Api.post('/users/login', payload);
      commit('SET_TOKEN', data.accessToken);

      await dispatch('loadUser');
    },
    async loadUser({ dispatch, commit }) {
      const user = await dispatch('users/profile', null, { root: true });
      commit('SET_USER', user);
    },
    async renew({ dispatch }) {
      // TODO - Renew token
      await dispatch('logout');
      return Promise.reject(new UnauthorizedError('Unauthorized'));
    },
    logout({ commit }) {
      commit('CLEAR_SESSION');
    },
    setCompany({ commit }, payload) {
      commit('SET_COMPANY', payload);
      commit('SET_CUSTOMER', null);
    },
    async setCustomer({ commit, state, dispatch }, payload) {
      commit('SET_CUSTOMER', payload);

      const customer = state.company?.customers?.find((c) => c.userCustomerId === payload);

      if (customer) {
        const { tradingName, companyName, cnpj, userCustomerId } = customer;
        const nomeCliente = tradingName || companyName;

        dispatch('createWebhookLogin', {
          nomeCliente,
          cnpjCliente: cnpj,
          idCompany: state.company.id,
          userCustomerId,
        });
      }
    },

    async createWebhookLogin(_, params) {
      const { data } = await Api.post('/users/webhook', params);
      return data;
    },

    updateCompany({ commit }, payload) {
      commit('UPDATE_COMPANY', payload);
    },
  },

  mutations: {
    UPDATE_COMPANY(state, company) {
      const oldValue = { ...state.company };
      state.company = {
        ...oldValue,
        ...company,
      };
    },

    SET_TOKEN(_, token = null) {
      setInStorage(KEY_ACCESS_TOKEN, token);
      setApiAccessToken(token);
    },
    SET_USER(state, payload) {
      state.user = { ...state.user, ...payload };
      if (state.user.avatar) {
        state.user.avatar = `${state.user.avatar}?no-cache=${Date.now()}`;
      }
    },
    SET_COMPANY(state, companyId = null) {
      const company = state.user?.companies.find((c) => c.id === companyId);
      const mergeCompany = { ...state.company, ...company };

      setInStorage(KEY_COMPANY, mergeCompany.id);

      setApiTenant(mergeCompany && mergeCompany.tenant);

      setPrimaryColor((mergeCompany && mergeCompany.primaryColor) || defaultPrimaryColor);

      state.company = mergeCompany;
      if (state.company.logo) state.company.logo = `${state.company.logo}?no-cache=${Date.now()}`;

      document.title = `${state.company.tradingName} B2B`;
    },
    SET_CUSTOMER(state, customerId) {
      const customer = state.company?.customers?.find((c) => c.userCustomerId === customerId);

      setInStorage(KEY_CUSTOMER, customerId);

      if (customer) {
        setApiUserCustomer(customerId);
      }

      state.customer = customer;
    },
    CLEAR_SESSION(state) {
      clearStorage();

      setApiTenant(null);
      setApiAccessToken(null);
      setApiUserCustomer(null);

      setPrimaryColor(defaultPrimaryColor);

      state.user = null;
      state.company = null;
      state.customer = null;

      document.title = process.env.VUE_APP_DEFAULT_TITLE;
    },
  },
};
