<template functional>
  <tbody class="b2b-table__row b2b-table__row--hoverable"
    :class="(!props.item['ativo'] || props.item['ativo'] === 0) && 'b2b-table__row--inactive'"
  >
    <tr @click.stop="listeners.click">
      <template v-for="header in props.headers">
        <td :key="header.value" :class="injections.methods.headerClasses(header)">
          <slot :name="`items:${header.value}`" v-bind="{ item: props.item, header }">
            {{ props.item[header.value] }}
          </slot>
        </td>
      </template>
    </tr>

    <slot name="extra-rows" />
  </tbody>
</template>

<script>
export default {
  name: 'b2b-table-row',
  props: {
    item: Object,
    headers: Array,
  },
  inject: {
    methods: {
      default: {
        headerClasses(header) {
          if (header.align) return `text--${header.align}`;
          if ([Number, Date].includes(header.type)) return 'text--right';
          return 'text--left';
        },
      },
    },
  },
};
</script>

<style lang="scss">
.b2b-table__row {
  & th {
    font-size: .75rem;
    color: $colorMainPrimary;

    user-select: none;
  }

  & td {
    height: 48px;
    padding: 0 16px;

    font-size: .875rem;

    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }

  &--hoverable {
    > tr:first-child:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &--striped {
    > tr:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &--inactive {
    text-decoration: line-through;
    opacity: 0.5;
  }
}
</style>
