export default [
  {
    path: '/orders',
    name: 'OrdersIndex',
    component: () => import(/* webpackChunkName: "orders" */ '../../views/OrdersIndex.vue'),
    meta: {
      requiresAuth: true,
      config: {
        header: true,
      },
    },
  },
  {
    path: '/orders/:id',
    name: 'OrdersShow',
    component: () => import(/* webpackChunkName: "orders" */ '../../views/OrdersShow.vue'),
    meta: {
      requiresAuth: true,
      config: {
        header: true,
        back: { name: 'OrdersIndex' },
      },
    },
  },
];
