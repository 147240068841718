export function textColor(color, blackOrWhite) {
  let hex = color;

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }

  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);

  if (blackOrWhite) {
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#111111' : '#FFFFFF';
  }

  r = (255 - r).toString(16).padStart(2, '0');
  g = (255 - g).toString(16).padStart(2, '0');
  b = (255 - b).toString(16).padStart(2, '0');

  return `#${r}${g}${b}`;
}

export function setPrimaryColor(color) {
  const root = document.documentElement;
  root.style.setProperty('--main-color--primary', color);

  const textValue = textColor(color, true);
  root.style.setProperty('--main-color--primary-text', textValue);
}

export function toSizeValue(value) {
  if (!value) return undefined;
  return Number.isNaN(+value, 10) ? value : `${Number(value)}px`;
}
