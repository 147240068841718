<template>
  <div class="b2b-search"
    :class="{
      'b2b-search--flat': flat,
      'b2b-search--flat__bordered': flat && bordered,
    }"
  >
    <div class="b2b-search__wrap" @click.stop="focus">
      <div class="b2b-search__icon">
        <search-icon size="24" />
      </div>

      <input
        v-model="model"
        ref="input"
        class="b2b-search__input"
        :placeholder="`${$t('labels.search')}${hideCodeSearch ? '' : $_isMobile
          ? `(${ $t('labels.search-code-mobile') })` : `(${ $t('labels.search-code') })` }`" />

      <div v-show="clearable && model" class="b2b-search__clear" @click.stop="$_onClickClear">
        <x-icon size="24" />
      </div>

      <slot name="append">
        <div v-if="appendIcon" class="b2b-search__append-icon" @click.stop="$_onClickAppendIcon">
          <p>{{ $t('labels.filters') }}</p>
          <component :is="appendIcon" />
        </div>
      </slot>
    </div>

    <div ref="expand" class="b2b-search__extends">
      <slot name="expand" />
    </div>
  </div>
</template>

<script>
import { SearchIcon, XIcon } from 'vue-feather-icons';

import DebounceMixin from '../../../mixins/base/debounce-mixin';
import BreakpointMixin from '../../../mixins/base/breakpoints-mixin';

export default {
  name: 'b2b-search-input',

  components: {
    SearchIcon,
    XIcon,
  },

  mixins: [
    BreakpointMixin(),
    DebounceMixin(),
  ],

  props: {
    clearable: Boolean,
    appendIcon: [Object, String],
    expanded: {
      type: Boolean,
      default: true,
    },
    flat: Boolean,
    bordered: Boolean,
    hideCodeSearch: Boolean,
  },

  methods: {
    focus() {
      this.$refs.input.focus();
    },

    $_onClickClear() {
      this.model = '';
    },

    $_onClickAppendIcon($event) {
      this.$emit('click:append', $event);
    },

    $_expandCheck() {
      if (!this.$slots.expand || !this.expanded) this.$el.style.maxHeight = '48px';
      else {
        const height = this.$refs.expand.scrollHeight || this.$refs.expand.clientHeight;
        this.$el.style.maxHeight = `${48 + height + 1}px`;
      }
    },
  },

  watch: {
    expanded() {
      this.$_expandCheck();
    },
  },

  mounted() {
    this.$_expandCheck();

    this.$_observer = new MutationObserver(() => {
      this.$nextTick(this.$_expandCheck);
    });
    this.$_observer.observe(this.$refs.expand, { childList: true, subtree: true });
  },

  beforeDestroy() {
    this.$_observer.disconnect();
  },
};
</script>

<style lang="scss">
$containerHeight: 48px;
$contentHeight: 24px;

.b2b-search {
  width: 100%;

  padding: 0 16px;
  background: $colorBackgroundCard;
  border-radius: calc($containerHeight / 2);
  box-shadow: $boxShadowElevated;

  overflow: hidden;
  transition: all 0.2s;
  max-height: $containerHeight;

  &--flat {
    padding: 0 16px;
    box-shadow: none;

    &__bordered {
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  &__wrap {
    cursor: text;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: $containerHeight;
  }

  &__extends {
    padding: 8px 0;
    border-top: 1px solid $colorMainSecondary;
  }

  &__icon {
    height: $contentHeight;
    margin-right: calc(($containerHeight - $contentHeight) / 2);
    padding-right: calc(($containerHeight - $contentHeight) / 2);

    color: $colorMainSecondary;

    border-right: 1px solid $colorMainSecondary;
  }

  &__clear {
    cursor: pointer;
    height: $contentHeight;
    margin-left: calc(($containerHeight - $contentHeight) / 2);
  }

  &__append-icon {
    display: flex;
    cursor: pointer;
    height: $contentHeight;
    margin-left: calc(($containerHeight - $contentHeight) / 2);
  }

  &__input {
    width: 100%;
    height: $containerHeight;

    display: block;

    font-size: 18px;
    color: $colorMainPrimary;
    caret-color: $colorMainPrimary;

    border: none;

    &::placeholder {
      color: $colorMainSecondary;
    }
  }
}
</style>
