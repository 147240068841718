<template functional>
  <div
    class="flex"
    v-on="listeners"
    :class="{
      [data.staticClass]: !!data.staticClass,
      'flex--row': props.row,
      'flex--fill-height': props.fillHeight,
      'flex--block': props.block,
    }"
    :style="{
      flex: props.flex,
      alignItems: props.align,
      justifyContent: props.justify,
    }"
  >
    <slot>{{ data.domProps && data.domProps.textContent }}</slot>
  </div>
</template>

<script>
export default {
  name: 'b2b-flex',
  props: {
    flex: {
      type: [String, Number],
      default: 1,
    },
    align: String,
    justify: String,
    row: Boolean,
    fillHeight: Boolean,
    block: Boolean,
  },
};
</script>

<style lang="scss">
.flex {
  display: flex;
  flex-direction: column;

  &--row {
    flex-direction: row;
  }

  &--fill-height {
    height: 100%;
  }

  &--block {
    min-width: 100%;
  }
}
</style>
