export default [
  {
    path: '/messages',
    name: 'MessagesIndex',
    component: () => import(/* webpackChunkName: "messages" */ '../../views/MessagesIndex.vue'),
    meta: {
      requiresAdmin: true,
      config: {
        header: true,
      },
    },
  },
  {
    path: '/messages/:messageId',
    name: 'MessagesShow',
    component: () => import(/* webpackChunkName: "messages" */ '../../views/MessagesShow.vue'),
    meta: {
      requiresAdmin: true,
      config: {
        header: true,
        back: { name: 'MessagesIndex' },
      },
    },
  },
];
