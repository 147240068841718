var render = function render(_c,_vm){return _c('div',_vm._g({staticClass:"flex",class:{
    [_vm.data.staticClass]: !!_vm.data.staticClass,
    'flex--row': _vm.props.row,
    'flex--fill-height': _vm.props.fillHeight,
    'flex--block': _vm.props.block,
  },style:({
    flex: _vm.props.flex,
    alignItems: _vm.props.align,
    justifyContent: _vm.props.justify,
  })},_vm.listeners),[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.data.domProps && _vm.data.domProps.textContent))]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }