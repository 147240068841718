export default [
  {
    path: '/favorites',
    name: 'FavoritesIndex',
    component: () => import(/* webpackChunkName: "favorites" */ '../../views/FavoritesIndex.vue'),
    meta: {
      requiresAuth: true,
      config: {
        header: true,
      },
    },
  },
];
