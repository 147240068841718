import ResourcePattern from '../utils/resource-pattern';

function filterAdmins({ email = '', name = '' }, search = '') {
  return email.includes(search) || name.includes(search);
}

export default ResourcePattern('companies', ({ Api, resource }) => ({
  actions: {
    async index({ dispatch }, params) {
      const { items, ...result } = await dispatch('$index', params);
      return {
        ...result,
        items: items.map(({ isActive, ...attrs }) => ({ ...attrs, ativo: isActive })),
      };
    },

    async uploadLogo(_, { id, form }) {
      const { data } = await Api.post(`${resource}/${encodeURIComponent(id)}/upload`, form, {
        headers: {
          'content-type': `multipart/form-data; boundary=${form.boundary}`,
        },
      });

      return data;
    },

    async uploadIcon(_, { id, form }) {
      const { data } = await Api.post(`${resource}/${encodeURIComponent(id)}/uploadIcon`, form, {
        headers: {
          'content-type': `multipart/form-data; boundary=${form.boundary}`,
        },
      });

      return data;
    },

    async admins(_, { tenant, ...params }) {
      const options = { params, headers: {} };
      if (tenant) {
        options.headers['x-tenant'] = tenant;
      }

      const { data, headers } = await Api.get(`${resource}/admins`, options);

      return {
        items: data.filter((admin) => filterAdmins(admin, params.s)),
        pages: headers['x-total-page'],
      };
    },

    async configs() {
      try {
        const { data } = await Api.get(`${resource}/configs${process.env.VUE_APP_DEFAULT_DOMAIN_CONFIG}`);
        return data;
      } catch (err) {
        return null;
      }
    },

    async updateStatusCompany(_, { id, form }) {
      const { data } = await Api.put(`${resource}/${id}/statusCompany`, form);
      return data;
    },

    async updateStatusUser(_, { id, form }) {
      const { data } = await Api.put(`${resource}/${id}/statusUser`, form);
      return data;
    },
  },
}));
