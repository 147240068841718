<template functional>
  <transition name="fade">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'b2b-transition-fade',
};
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
