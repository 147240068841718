<template functional>
  <div class="list__item__subtitle">
    <slot>{{ data.domProps && data.domProps.textContent }}</slot>
  </div>
</template>

<script>
export default {
  name: 'b2b-list-item-subtitle',
};
</script>

<style lang="scss">
.list__item__subtitle {
  font-size: .875rem;
  line-height: 1.2;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
