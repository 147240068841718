import DateRange from '../utils/DateRange';
import SyncPropsMixin from './base/sync-props-mixin';

export default function QueryWatchResolver(queries = {}, handler) {
  const computed = {};
  const watch = {};

  const mapQueries = {};

  Object.entries(queries).forEach(([key, options = {}]) => {
    mapQueries[key] = options.key || key;

    const type = options.type || options;

    computed[`$_${key}`] = {
      get() {
        const value = this.query[key];

        if (type === Array) {
          return Array.isArray(value) ? value : [];
        }

        if (type === Object) {
          return typeof value === 'object' ? value : {};
        }

        if (type === DateRange) {
          return value || DateRange();
        }

        return value;
      },

      set(value) {
        this.$emit('update:query', {
          ...this.query,
          [key]: value,
        });
      },
    };

    if (options.watch !== false && !!handler) {
      if (type === DateRange) {
        watch[`query.${key}.start`] = function handlerDateRange(newValue, oldValue) {
          handler.call(this, key, newValue, oldValue);
        };

        watch[`query.${key}.end`] = function handlerDateRange(newValue, oldValue) {
          handler.call(this, key, newValue, oldValue);
        };
      } else {
        watch[`query.${key}`] = function handlerWatch(newValue, oldValue) {
          if (type === Array && oldValue?.length === 0 && newValue?.length === 0) {
            return;
          }

          handler.call(this, key, newValue, oldValue);
        };
      }
    }
  });

  return {
    mixins: [
      SyncPropsMixin({
        query: Object,
      }),
    ],

    computed,

    methods: {
      $_mapQuery() {
        return Object.fromEntries(Object.entries(this.query)
          .map(([key, value]) => [mapQueries[key] || key, value || undefined]));
      },
    },

    watch,
  };
}
