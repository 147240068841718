import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('acom', ({ Api, resource }) => ({
  actions: {
    async uploadAd(_, { id, form }) {
      const { data } = await Api.post(`${resource}/${encodeURIComponent(id)}/upload`, form, {
        headers: {
          'content-type': `multipart/form-data; boundary=${form.boundary}`,
        },
      });

      return data;
    },
  },
}));
