export default class DebouncePromise {
  static DEBOUNCE_ERROR = 'Debounce stoped';

  constructor(timer) {
    this.timer = timer;
  }

  start(callback) {
    return new Promise((resolve, reject) => {
      this.resolve = resolve;

      this.timeout = setTimeout(() => {
        callback(resolve, reject);
      }, this.timer);
    });
  }

  stop() {
    clearTimeout(this.timeout);

    if (!this.resolve) return;
    this.resolve(new Error(DebouncePromise.DEBOUNCE_ERROR));
  }

  static stop(debounce) {
    if (!debounce?.stop) return;
    debounce.stop();
  }

  static isDebounceError(error) {
    return error instanceof Error && error.message === DebouncePromise.DEBOUNCE_ERROR;
  }
}
