import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('produtos', ({ Api, resource }) => ({
  actions: {
    async toggleFavorite(_, { id }) {
      const { data } = await Api.patch(`${resource}/${encodeURIComponent(id)}/favorite`);
      return data;
    },

    async upload(_, { id, form }) {
      const { data } = await Api.post(`${resource}/${encodeURIComponent(id)}/upload`, form, {
        headers: {
          'content-type': `multipart/form-data; boundary=${form.boundary}`,
        },
      });

      return data;
    },

    async exportProducts(_, params) {
      const { data } = await Api.get(`${resource}/export`, { params });
      return {
        items: data,
      };
    },
  },
}));
