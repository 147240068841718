<template>
  <b2b-overlay v-model="isActive" role="dialog">
    <b2b-card v-if="message" width="460" fill-height>
      <div class="message--header">
        <b2b-card-title v-text="$_title" class="message--title" row />
        <b2b-button @click.stop="$_onClickCancel" v-text="$t('x')" class="message--close" dense />
      </div>

      <div class="message--content" v-if="message[pageNumber]">
        <b2b-card-text class="mt--2" v-text="message[pageNumber].texto" fill-height />

        <b2b-card-actions
          :centered="centered"
          :justifyCenter="justifyCenter">
          <b2b-button v-if="Boolean(message[pageNumber].permitirLido)"
            @click.stop="$_onClickOk(message[pageNumber])"
            v-text="$t('labels.lido')"
            dense />
        </b2b-card-actions>
      </div>

      <div class="message--paginate" v-if="message.length > 1">
        <div class="paginate--button">
          <span @click="$_prevPage">Voltar</span>

          <span>{{ pageNumber + 1 }} / {{ message.length }}</span>

          <span @click="$_nextPage">Proximo</span>
        </div>
      </div>
    </b2b-card>
  </b2b-overlay>
</template>

<script>
import ActionResolver from '../../mixins/action-resolver';
import ActivableMixin from '../../mixins/base/activable-mixin';

import B2bOverlay from '../core/b2b-overlay.vue';
import B2bCard from '../core/card/b2b-card.vue';
import B2bCardTitle from '../core/card/b2b-card-title.vue';
import B2bCardText from '../core/card/b2b-card-text.vue';
import B2bCardActions from '../core/card/b2b-card-actions.vue';
import B2bButton from '../core/buttons/b2b-button.vue';

export default {
  name: 'b2b-dialog-confirm',

  mixins: [
    ActionResolver(),
    ActivableMixin(),
  ],

  components: {
    B2bOverlay,
    B2bCard,
    B2bCardTitle,
    B2bCardText,
    B2bCardActions,
    B2bButton,
  },

  props: {
    message: Array,
    customerId: String,
    title: String,
    permitirLido: Boolean,
  },

  data() {
    return {
      centered: true,
      justifyCenter: true,
      pageNumber: 0,
    };
  },

  computed: {
    $_title() {
      return this.title || this.$tc('errors.warning');
    },
  },

  methods: {
    $_onClickCancel() {
      this.isActive = false;
    },

    async $_onClickOk(item, $event) {
      if (item) {
        await this.$_actionResolver({
          action: 'messages/updateMarkReadNotification',
          params: {
            id: item.id,
            form: {
              lido: 1,
              clienteUsuarioId: this.customerId,
            },
          },
        });

        this.$emit('click:confirm', $event);
        this.isActive = false;
      }
    },

    $_prevPage() {
      if (this.pageNumber <= 0) return;
      this.pageNumber -= 1;
    },

    $_nextPage() {
      if ((this.pageNumber + 1) >= this.message.length) return;
      this.pageNumber += 1;
    },
  },
};
</script>

<style scoped>
.b2b-card__text {
  text-align: center;
}

.message--header {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.message--title {
  align-items: center;
}

.message--close {
  width: 10px;
  padding: 20px;
}

.message--content {
  background: rgb(243, 243, 243);
  display: block;
  min-width: 100%;
  min-height: 140px;
  /* padding: 10px; */
}

.paginate--button {
  justify-content: space-between;
}

.message--paginate .paginate--button {
  width: 100%;
  margin: auto;
  padding: 20px;
  display: flex;
}

.message--paginate {
  color: #5f5f5f;
  padding: 0px 8px;
  text-decoration: none;
}

.active span,
.message--paginate span:hover {
  color: #000 !important;
}

.message--paginate span {
  font-weight: normal;
  cursor: pointer;
}
</style>
