<template>
  <b2b-overlay class="b2b-dialog-error" v-model="isActive" role="dialog">
    <b2b-card width="300" fill-height>
      <b2b-card-title :class="$_classes" v-text="$_title" row />

      <b2b-card-text class="mt--2 b2b-dialog-error__content" v-text="$_message" fill-height />

      <b2b-card-actions>
        <b2b-button @click.stop="$_onClickOk" v-text="$t('labels.ok')" dense />
      </b2b-card-actions>
    </b2b-card>
  </b2b-overlay>
</template>

<script>
import ActivableMixin from '../../mixins/base/activable-mixin';

import ForbiddenError from '../../utils/ForbiddenError';

import B2bOverlay from '../core/b2b-overlay.vue';
import B2bCard from '../core/card/b2b-card.vue';
import B2bCardTitle from '../core/card/b2b-card-title.vue';
import B2bCardText from '../core/card/b2b-card-text.vue';
import B2bCardActions from '../core/card/b2b-card-actions.vue';
import B2bButton from '../core/buttons/b2b-button.vue';

export default {
  name: 'b2b-dialog-error',
  mixins: [
    ActivableMixin(),
  ],
  components: {
    B2bOverlay,
    B2bCard,
    B2bCardTitle,
    B2bCardText,
    B2bCardActions,
    B2bButton,
  },
  props: {
    error: Error,
  },
  computed: {
    $_forbidden() {
      return this.error && this.error instanceof ForbiddenError;
    },
    $_title() {
      return (this.error && this.error.title)
        || (!this.$_forbidden ? this.$t('errors.generic-title') : this.$t('errors.warning'));
    },
    $_message() {
      return (this.error && this.error.message) || this.$t('errors.generic-message');
    },
    $_classes() {
      return !this.$_forbidden ? 'error white--text' : 'warning white--text';
    },
  },
  methods: {
    $_onClickOk() {
      this.isActive = false;
    },
  },
};
</script>

<style lang="scss">
.b2b-dialog-error {
  &__content {
    white-space: pre-line;
  }
}
</style>
