export default {
  path: '/about',
  name: 'About',
  component: () => import(/* webpackChunkName: "about" */ '../../views/About.vue'),
  meta: {
    config: {
      header: true,
    },
  },
};
