var render = function render(_c,_vm){return _c(_vm.props.to ? 'router-link' : 'div',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.injections.methods.vShow(_vm.data)),expression:"injections.methods.vShow(data)"}],tag:"component",staticClass:"b2b-card",class:{
    ..._vm.data.class,
    [_vm.data.staticClass]: !!_vm.data.staticClass,
    'b2b-card--row': _vm.props.row,
    'b2b-card--flat': _vm.props.flat,
    'b2b-card--fill-height': _vm.props.fillHeight,
    'b2b-card--fill-width': _vm.props.fillWidth,
    'b2b-card--clickable': !!_vm.listeners.click || _vm.props.to,
    'b2b-card--hoverable': !!(_vm.listeners.click || _vm.props.to || _vm.props.hoverable),
    'b2b-card--inactive': _vm.props.inactive,
  },style:({
    ..._vm.data.style,
    maxWidth: _vm.injections.methods.toSizeValue(_vm.props.maxWidth),
    width: _vm.injections.methods.toSizeValue(_vm.props.width),
    height: _vm.injections.methods.toSizeValue(_vm.props.height),
  }),attrs:{"to":_vm.props.to,"replace":_vm.props.replace}},'component',{
    ref: _vm.data.ref,
    ..._vm.data.attrs,
  },false),_vm.listeners),[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.data.domProps && _vm.data.domProps.textContent))]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }