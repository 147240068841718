<template>
  <div class="b2b-loading">
    <div class="b2b-loading__animation" />
    <div class="b2b-loading__bubbles">
      <div class="b2b-loading__person b2b-loading__person--left">
        <user-icon />
      </div>
      <div class="b2b-loading__logo">B2B</div>
      <div class="b2b-loading__person b2b-loading__person--right">
        <user-icon size="24" />
      </div>
    </div>
  </div>
</template>

<script>
import { UserIcon } from 'vue-feather-icons';

export default {
  name: 'b2b-app-loading',
  components: {
    UserIcon,
  },
};
</script>

<style lang="scss">
$color: #9E9E9E;

$dashWidth: 72px;
$dashHeight: 5px;

$logoSize: 100px;
$usersSize: 70px;
$spacingSize: 16px;
$borderSize: 5px;

$animationTime: 1.25s;

.b2b-loading {
  width: 100%;
  height: $logoSize;
  max-height: $logoSize;

  flex: 1 1 auto;
  user-select: none;
  position: relative;

  &__bubbles {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    align-items: center;
    justify-content: center;
  }

  &__logo {
    width: $logoSize;
    height: $logoSize;
    margin: 0 $spacingSize;

    border-radius: calc($logoSize / 2);
    border: $borderSize solid $color;

    color: $color;
    font-size: 42px;
    font-weight: 300;
    line-height: 90px;
    text-align: center;
  }

  &__person {
    width: $usersSize;
    height: $usersSize;
    position: relative;

    border-radius: calc($usersSize / 2);
    border: $borderSize solid $color;

    svg {
      width: 60px;
      height: 60px;

      stroke: $color;
      stroke-width: 1px;
    }

    &::before {
      $dashContentHeight: 25px;

      height: $dashContentHeight;
      left: $usersSize - ($borderSize * 2);
      width: $spacingSize + ($borderSize * 2);
      top: calc($usersSize / 2) - calc($dashContentHeight / 2) - $borderSize;

      content: '';
      display: block;
      position: absolute;

      background:
        url('../../assets/loading-dash.svg'),
        url('../../assets/loading-dash.svg');

      background-size:
        $dashWidth $dashHeight,
        $dashWidth $dashHeight;

      background-repeat: repeat-x, repeat-x;
      background-position: left top, right bottom;

      animation: b2b-loading-border-dance $animationTime infinite linear;
    }

    &--right {
      &::before {
        left: -($spacingSize + ($borderSize * 2));
        animation-delay: calc($animationTime / 2);
      }
    }
  }
}

@keyframes b2b-loading-border-dance {
  0% {
    background-position:
      left top,
      right bottom;
  }
  50% {
    background-position:
      left $dashWidth top,
      right bottom;
  }
  100% {
    background-position:
      left $dashWidth top,
      right $dashWidth bottom;
  }
}
</style>
