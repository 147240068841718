<template functional>
  <div role="listbox" class="list" :class="data.staticClass">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'b2b-list',
};
</script>

<style>
.list {
  margin: 0;
  padding: 4px 0;
  list-style-type: none;
}
</style>
