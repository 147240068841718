<template functional>
  <div class="b2b-grid" :class="{
    [data.staticClass]: data.staticClass,
    'b2b-grid--fill-height': props.fillHeight,
  }">
    <slot>{{ data.domProps && data.domProps.textContent }}</slot>
  </div>
</template>

<script>
export default {
  name: 'b2b-grid',
  props: {
    fillHeight: Boolean,
  },
};
</script>

<style lang="scss">
.b2b-grid {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;

  &--fill-height {
    height: 100%;
  }
}
</style>
