<template>
  <div class="b2b-input-layout"
    :class="{
      'b2b-input-layout--hidden': hidden,
      'b2b-input-layout--has-value': !!value,
      'b2b-input-layout--focused': !!focused,
      'b2b-input-layout--disabled': disabled,
    }"
  >
    <slot name="prepend" />

    <slot />

    <div class="b2b-input-layout__border">
      <div class="b2b-input-layout__border-left" />
      <div class="b2b-input-layout__border-label">
        <label class="input__label" :for="id" v-text="label" />
      </div>
      <div class="b2b-input-layout__border-right" />
    </div>

    <slot name="append" />
  </div>
</template>

<script>
export default {
  name: 'b2b-input-layout',

  props: {
    id: String,
    value: null,
    label: String,
    hidden: Boolean,
    disabled: Boolean,
    focused: Boolean,
  },
};
</script>

<style lang="scss">
$borderWidth: calc($inputHeight / 2);

.b2b-input-layout {
  position: relative;
  margin-bottom: 16px;

  display: flex;
  flex-direction: row;

  &__input {
    width: 100%;
    height: $inputHeight;
    padding: 0 $borderWidth;

    z-index: 0;
    position: relative;

    border: none;
    background: transparent;
    border-radius: $borderWidth;

    transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    &:active,
    &:focus {
      + div > div {
        border-top-width: 2px;
        border-bottom-width: 2px;
        border-color: var(--main-color--primary);

        &:first-child {
          border-left-width: 2px;
        }

        &:last-child {
          border-right-width: 2px;
        }

        > label {
          color: var(--main-color--primary);
        }
      }
    }
  }

  &__border {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 1;
    position: absolute;

    display: flex;
    flex-direction: row;

    pointer-events: none;

    transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  &__border-left,
  &__border-label,
  &__border-right {
    border-width: 1px;
    border-style: solid;
    border-color: $colorMainPrimary;

    transition: border-width 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  &__border-left {
    width: $borderWidth;

    display: flex;
    align-items: center;
    justify-content: center;

    border-right-width: 0;
    border-top-left-radius: $borderWidth;
    border-bottom-left-radius: $borderWidth;
  }

  &__border-right {
    flex: 1;

    border-left-width: 0;
    border-top-right-radius: $borderWidth;
    border-bottom-right-radius: $borderWidth;
  }

  &__border-label {
    border-left-width: 0;
    border-right-width: 0;

    border-top-width: 1px;
    border-top-color: $colorMainPrimary;

    > label {
      padding: 0 4px;
      position: relative;
      top: calc(50% - 12px);

      font-size: $inputFontSize;

      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

      &:empty {
        display: none;
      }
    }
  }

  &__right-icon {
    top: 12px;
    right: 12px;
    position: absolute;
    cursor: pointer;
    pointer-events: none;
  }

  &--hidden {
    display: none;
  }

  &--focused &__border-label,
  &--has-value &__border-label {
    border-top-width: 2px;
    border-top-color: transparent !important;

    > label {
      top: -12px;
    }
  }

  &--disabled {
    opacity: 0.5;
  }
}
</style>
