<template functional>
  <div class="b2b-card__title" :class="data.staticClass || data.class">
    <slot>{{ data.domProps && data.domProps.textContent }}</slot>

    <span v-if="slots().caption" class="b2b-card__title__caption">
      <slot name="caption" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'b2b-card-title',
};
</script>

<style lang="scss">
.b2b-card__title {
  padding: 8px 16px;
  position: relative;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  font-weight: 500;
  line-height: 2rem;
  font-size: 1.25rem;
  word-break: break-all;
  letter-spacing: 0.0125em;

  &.flex--column {
    flex-direction: column;
  }

  &.align--center {
    align-items: center;
  }

  &__caption {
    top: 12px;
    right: 16px;
    position: absolute;

    font-weight: 400;
    font-size: .75rem;
    letter-spacing: 0.0333333333em;
    line-height: 1.25rem;
  }
}
</style>
