import Vue from 'vue';
import App from './App.vue';

import 'roboto-fontface/css/roboto/roboto-fontface.css';

import './registerServiceWorker';

import './styles/typography.scss';
import './styles/helpers.scss';
import './styles/global.scss';

import './plugins/event-hub';
import i18n from './plugins/i18n';

import router from './router';
import store from './store';

import cpf from './filters/cpf';
import cnpj from './filters/cnpj';
import cep from './filters/cep';
import document from './filters/document';
import customerAddress from './filters/customer-address';
import numberFormat from './filters/number-format';
import date from './filters/date';

Vue.config.productionTip = false;
Vue.config.devtools = false;

Vue.filter('cpf', cpf);
Vue.filter('cnpj', cnpj);
Vue.filter('cep', cep);
Vue.filter('document', document);
Vue.filter('customer-address', customerAddress);
Vue.filter('number-format', numberFormat);
Vue.filter('date', date);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
