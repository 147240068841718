export default [
  {
    path: '/banners',
    name: 'BannersIndex',
    component: () => import(/* webpackChunkName: "banners" */ '../../views/BannersIndex.vue'),
    meta: {
      requiresAdmin: true,
      config: {
        header: true,
      },
    },
  },
  {
    path: '/banners/:bannerId',
    name: 'BannersShow',
    component: () => import(/* webpackChunkName: "banners" */ '../../views/BannersShow.vue'),
    meta: {
      requiresAdmin: true,
      config: {
        header: true,
        back: { name: 'BannersIndex' },
      },
    },
  },
];
