<template>
  <div class="b2b-tooltip">
    <slot name="activator" />

    <div ref="label" class="b2b-tooltip__content">
      <slot>{{ label }}</slot>
    </div>
  </div>
</template>

<script>
import MutationObserverResolver from '../../mixins/mutation-observer-resolver';

export default {
  name: 'b2b-tooltip',

  mixins: [
    MutationObserverResolver(),
  ],

  props: {
    label: String,
  },

  methods: {
    $_setLabelStyles() {
      if (!this.$refs.label) {
        return;
      }

      const activatorWidth = this.$el.firstChild.clientWidth;
      const labelWidth = this.$refs.label.clientWidth;

      const center = (activatorWidth - labelWidth) / 2;
      this.$refs.label.style.left = `${center}px`;
    },
  },

  mounted() {
    this.$_setLabelStyles();
    this.$_labelObserver = this.$_observeResolver(this.$refs.label, this.$_setLabelStyles);
  },

  beforeDestroy() {
    this.$_labelObserver.disconnect();
  },
};
</script>

<style lang="scss">
.b2b-tooltip {
  flex: 0;
  position: relative;
  display: inline-flex;
  line-height: initial !important;
  font-size: 14px;

  &__content {
    pointer-events: none;

    padding: 4px 8px;
    border-radius: 8px;

    top: 0;
    z-index: 100;
    width: max-content;
    position: absolute;

    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.64);

    transform-origin: center;

    transform: scale(0);
    transition: all 0.2s ease-in-out;
  }

  &:hover > &__content {
    transform: scale(1);
    top: calc(100% + 4px);
  }
}

@media (max-width: 630px) {
  .b2b-tooltip__content {
    display: none !important;
  }
}
</style>
