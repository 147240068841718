import Vue from 'vue';

export default {
  install(VueInstance) {
    const hub = new Vue();

    Object.defineProperties(hub, {
      on: {
        get() { return hub.$on; },
      },
      emit: {
        get() { return hub.$emit; },
      },
      off: {
        get() { return hub.$off; },
      },
    });

    Object.defineProperty(VueInstance.prototype, '$hub', {
      get() { return hub; },
    });

    Object.defineProperty(VueInstance, 'hub', {
      get() { return hub; },
    });
  },
};
