<template>
  <b2b-menu v-model="isActive" class="b2b-profile-menu">
    <template #activator="{ on }">
      <b2b-tooltip :label="$t('resources.menu.profile')" style="display: flex;">
        <template #activator>
          <b2b-avatar v-on="on" :src="$_user.avatar" size="48" class="b2b-profile-menu__avatar" />
        </template>
      </b2b-tooltip>
    </template>

    <b2b-list>
      <b2b-list-item v-for="item in $_menu" :key="item.route.name" :to="item.route">
        <b2b-list-item-title v-text="$tc(item.title, 2)" />
      </b2b-list-item>
    </b2b-list>

    <b2b-divider no-margin />

    <!-- <b2b-list>
      <b2b-list-item :to="{ name: 'Help' }">
        <b2b-list-item-title v-text="$t('resources.menu.help')" />
      </b2b-list-item>

      <b2b-list-item :to="{ name: 'About' }">
        <b2b-list-item-title v-text="$tc('resources.menu.about', 2)" />
      </b2b-list-item>
    </b2b-list>

    <b2b-divider no-margin /> -->

    <b2b-list>
      <b2b-list-item v-if="$_manyCompanies" @click="$_onClickManyCompanies">
        <b2b-list-item-title v-text="$_changeCompaniesLabel" />
      </b2b-list-item>

      <b2b-list-item v-if="$_manyCustomers" @click="$_onClickManyCustomers">
        <b2b-list-item-title v-text="$_changeCustomersLabel" />
      </b2b-list-item>

      <b2b-list-item :to="$_changeProfileRoute">
        <b2b-list-item-title v-text="$_changeProfileLabel" />
      </b2b-list-item>

      <b2b-divider no-margin />

      <b2b-download />

      <!-- mover quando finalizado o HELP -->
      <b2b-list-item @click="$_openContact"
        v-if="contact && (!$_isAdmin && !$_isMaster)">
        <b2b-list-item-title v-text="$t('resources.menu.contact')" />
      </b2b-list-item>

      <b2b-list-item :to="{ name: 'About' }">
        <b2b-list-item-title v-text="$tc('resources.menu.about', 2)" />
      </b2b-list-item>

      <b2b-list-item @click.stop="$_onClickSignOut">
        <b2b-list-item-title v-text="$t('labels.sign-out')" />
      </b2b-list-item>
    </b2b-list>
  </b2b-menu>
</template>

<script>
import ActionResolver from '../../mixins/action-resolver';

import B2bMenu from '../core/b2b-menu.vue';
import B2bList from '../core/list/b2b-list.vue';
import B2bListItem from '../core/list/b2b-list-item.vue';
import B2bListItemTitle from '../core/list/b2b-list-item-title.vue';
import B2bDivider from '../core/b2b-divider.vue';
import B2bAvatar from '../core/b2b-avatar.vue';

import B2bDownload from './b2b-download/b2b-download.vue';

import B2bTooltip from '../core/b2b-tooltip.vue';

const PAGE_COMPANIES = { title: 'resources.menu.companies', route: { name: 'CompaniesIndex' } };
const PAGE_CUSTOMERS = { title: 'resources.menu.customers', route: { name: 'CustomersIndex' } };

const PAGE_PRODUCTS = { title: 'resources.products.resource', route: { name: 'Products', query: { f: 'ativos' } } };
const PAGE_ORDERS = { title: 'resources.menu.orders', route: { name: 'OrdersIndex' } };
const PAGE_FAVORITES = { title: 'resources.menu.favorites', route: { name: 'FavoritesIndex' } };
const PAGE_ADS = { title: 'resources.banners.resource', route: { name: 'BannersIndex' } };
const PAGE_MESSAGES = { title: 'resources.mensagens.resource', route: { name: 'MessagesIndex', query: { f: 'ativos' } } };

export default {
  name: 'b2b-profile-menu',

  components: {
    B2bMenu,
    B2bList,
    B2bListItem,
    B2bListItemTitle,
    B2bDivider,
    B2bAvatar,
    B2bDownload,
    B2bTooltip,
  },

  mixins: [
    ActionResolver(),
  ],

  data() {
    return {
      isActive: false,

      contact: null,

      menus: {
        master: [
          PAGE_COMPANIES,
        ],
        admin: [
          PAGE_CUSTOMERS,
          PAGE_ORDERS,
          PAGE_PRODUCTS,
          PAGE_ADS,
          PAGE_MESSAGES,
        ],
        customer: [
          PAGE_ORDERS,
          PAGE_FAVORITES,
        ],
      },
    };
  },

  computed: {
    $_menu() {
      let menu = [];

      if (this.$store.getters['session/isMaster']) {
        menu = menu.concat(this.menus.master);
      }

      if (this.$store.getters['session/isAdmin']) {
        menu = menu.concat(this.menus.admin);
      }

      if (this.$store.getters['session/isCustomer']) {
        menu = menu.concat(this.menus.customer);
      }

      return menu;
    },

    $_user() {
      return this.$store.getters['session/user'] || {};
    },

    $_manyCompanies() {
      return this.$store.getters['session/user']?.companies?.length > 1;
    },

    $_manyCustomers() {
      return this.$store.getters['session/company']?.customers?.length > 1;
    },

    $_changeCompaniesLabel() {
      return `${this.$t('labels.change')} ${this.$tc('resources.companies.resource', 1)}`;
    },

    $_changeCustomersLabel() {
      return `${this.$t('labels.change')} ${this.$tc('resources.customers.resource', 1)}`;
    },

    $_isAdmin() {
      return this.$store.getters['session/isAdmin'];
    },

    $_isMaster() {
      return this.$store.getters['session/isMaster'];
    },

    $_company() {
      return this.$store.getters['session/company'] || {};
    },

    $_changeProfileLabel() {
      if (this.$_isAdmin && !this.$_isMaster) {
        return this.$t('resources.menu.config');
      }

      return this.$t('resources.menu.profile');
    },

    $_changeProfileRoute() {
      if (this.$_isAdmin && !this.$_isMaster) {
        return '/settings';
      }

      return '/profile';
    },
  },

  methods: {
    $_onClickManyCompanies() {
      this.$hub.emit('select:company');
    },

    $_onClickManyCustomers() {
      this.$hub.emit('select:customer');
    },

    $_onClickSignOut() {
      this.$_actionResolver({
        action: 'session/logout',
        ignoreError: true,
      })
        .then(() => {
          this.$router.replace({ name: 'Login' });
        });
    },

    $_openContact() {
      const { contatoEnvio, contatoTipo } = this.contact;

      switch (contatoTipo) {
        case 'outros':
          const [, protocol, url] = /^(https?:\/\/)?(.*)$/.exec(contatoEnvio); // eslint-disable-line no-case-declarations
          window.open(`${protocol ?? 'https://'}${url}`, '_blank');
          break;
        case 'email':
          window.open(`mailto:${contatoEnvio}`, '_blank');
          break;
        case 'whatsapp':
          if (contatoEnvio.length >= 11) {
            window.open(`https://wa.me/55${contatoEnvio.replace(/[^\d]+/g, '')}`, '_blank');
          } else {
            window.open(`https://wa.me/${contatoEnvio.replace(/[^\d]+/g, '')}`, '_blank');
          }
          break;
        default:
          this.$hub.emit('dialog:ok', `${this.$t('resources.company-settings.contact.telefone')}: ${contatoEnvio}`, this.$t('resources.menu.contact'), true);
          break;
      }
    },
  },

  watch: {
    $route() {
      this.isActive = false;
    },

    async isActive() {
      if (this.isActive) {
        const company = this.$_company;

        if (company && company.id) {
          const dataForm = await this.$_actionResolver({
            action: 'contato/getConfig',
            params: { id: company.id },
          });

          if (dataForm.contatoEnvio && dataForm.contatoTipo) {
            this.contact = dataForm;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.b2b-profile-menu {
  &__avatar {
    cursor: pointer;
  }
}

.b2b-menu__content {
  margin-left: -190px !important;
  top: 85px !important;
  max-width: 246px !important;
  width: 256px !important;
  left: auto !important;
}
</style>
