<template>
  <b2b-overlay classes="b2b-dialog-stopwatch" v-model="isActive" role="dialog" :persistent="true">
    <b2b-card width="300" fill-height>
      <b2b-card-title class="b2b-dialog-stopwatch-title" v-text="$_title" row />

      <b2b-card-text class="mt--2" fill-height>
        <div class="b2b-dialog-stopwatch-text">
          <p class="b2b-dialog-stopwatch-contador">{{ $_getContadorMinutos }}</p>
          <p class="b2b-dialog-stopwatch-ponto">:</p>
          <p class="b2b-dialog-stopwatch-contador">{{ $_getContadorSegundos }}</p>
        </div>
      </b2b-card-text>
    </b2b-card>
  </b2b-overlay>
</template>

<script>
import ActivableMixin from '../../mixins/base/activable-mixin';

import B2bOverlay from '../core/b2b-overlay.vue';
import B2bCard from '../core/card/b2b-card.vue';
import B2bCardTitle from '../core/card/b2b-card-title.vue';
import B2bCardText from '../core/card/b2b-card-text.vue';

export default {
  name: 'b2b-dialog-stopwatch',

  mixins: [
    ActivableMixin(),
  ],

  components: {
    B2bOverlay,
    B2bCard,
    B2bCardTitle,
    B2bCardText,
  },

  props: {
    active: Boolean,
    title: String,
    milliseconds: Number,
  },

  data() {
    return {
      dateWatch: null,
      contador: {
        horas: '00',
        minutos: '00',
        segundos: '00',
      },
    };
  },

  computed: {
    $_title() {
      return this.title || this.$t('ok.generic-title');
    },

    $_message() {
      return this.message || this.$t('ok.generic-message');
    },

    $_milliseconds() {
      return this.milliseconds;
    },

    $_getContadorMinutos() {
      return this.contador.minutos;
    },

    $_getContadorSegundos() {
      return this.contador.segundos;
    },
  },

  watch: {
    active() {
      if (this.active) {
        const data = new Date();
        data.setMilliseconds(data.getMilliseconds() + this.$_milliseconds);

        this.dateWatch = data;

        this.$_atualizaContador();
      }
    },
  },

  methods: {
    $_onClickOk() {
      this.isActive = false;
    },

    $_atualizaContador() {
      const hoje = new Date();
      let ss = parseInt((this.dateWatch - hoje) / 1000, 0);

      let mm = parseInt(ss / 60, 0);
      let hh = parseInt(mm / 60, 0);
      const dd = parseInt(hh / 24, 0);

      ss -= (mm * 60);
      mm -= (hh * 60);
      hh -= (dd * 24);

      if (dd + hh + mm + ss > 0) {
        let horas = hh;
        let minutos = `${mm}`;
        let segundos = `${ss}`;
        if (hh <= 9) horas = `0${hh}`;
        if (mm <= 9) minutos = `0${mm}`;
        if (ss <= 9) segundos = `0${ss}`;

        this.contador = { horas, minutos, segundos };
        setTimeout(this.$_atualizaContador, 1000);
      } else {
        this.contador = { horas: '00', minutos: '00', segundos: '00' };
        setTimeout(this.$_onClickOk, 300);
      }
    },
  },
};
</script>

<style lang="scss">
.b2b-dialog-stopwatch {
  z-index: 102;
}

.b2b-dialog-stopwatch-title {
  justify-content: center;
}

.b2b-dialog-stopwatch-text {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.b2b-dialog-stopwatch-contador {
  padding: 10px;
  border-radius: 100%;
  border: 1.8px solid #6c6c6c;
  font-weight: bold;
}

.b2b-dialog-stopwatch-ponto {
  padding: 0 10px;
  font-size: 24px;
  margin-top: 3px;
  font-weight: 500;
}
</style>
