<template>
  <div class="b2b-list-item-avatar">
    <b2b-avatar :src="src" size="38" flat />
  </div>
</template>

<script>
import B2bAvatar from '../b2b-avatar.vue';

export default {
  name: 'b2b-list-item-avatar',
  components: {
    B2bAvatar,
  },
  props: {
    src: String,
  },
};
</script>

<style lang="scss">
.b2b-list-item-avatar {
  min-height: 100%;

  display: flex;
  align-items: center;
  flex-direction: row;
}
</style>
