import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('contato', ({ Api, resource }) => ({
  actions: {
    async updateConfig(_, { id, form }) {
      const { data } = await Api.put(`${resource}/${id}/config`, form);
      return data;
    },

    async getConfig(_, { id }) {
      const { data } = await Api.get(`${resource}/${id}/config`);
      return data;
    },
  },
}));
