<template>
  <label ref="label" class="b2b-checkbox" :for="id" @click.stop>
    <div class="b2b-checkbox__box">
      <input v-model="$_model" :id="id" :value="value" type="checkbox" />
      <component :is="$_icon" :class="$_color" />
    </div>
    <div v-if="label" class="b2b-checkbox__label" v-text="label" />
  </label>
</template>

<script>
import { SquareIcon, CheckSquareIcon } from 'vue-feather-icons';

import ModelMixin from '../../../mixins/base/model-mixin';

export default {
  name: 'b2b-checkbox',
  mixins: [
    ModelMixin([Array, Boolean], 'checked', 'change'),
  ],
  props: {
    id: String,
    value: null,
    label: String,
  },
  computed: {
    isChecked() {
      if (!Array.isArray(this.$_model)) return this.$_model;
      return this.$_model.includes(this.value);
    },
    $_icon() {
      return this.isChecked ? CheckSquareIcon : SquareIcon;
    },
    $_color() {
      return this.isChecked ? 'primary--text' : null;
    },
  },
};
</script>

<style lang="scss">
$height: 32px;

.b2b-checkbox {
  height: 100%;
  min-height: $height;

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  cursor: pointer;

  &__box {
    height: $height;

    display: flex;
    align-items: center;
    justify-content: center;

    > input {
      display: none;
    }
  }
  &__label {
    margin-left: 16px;
  }
}
</style>
