import ResourcePattern from '../utils/resource-pattern';
import DebouncePromise from '../../utils/DebouncePromise';

const changeItemDebounces = Object.create(null);

export default ResourcePattern('carrinhos', ({ Api, resource }) => ({
  state: {
    total: 0,
    volume: 0,
    mixProduto: 0,
    mixSKU: 0,
    estoque: 0,
    dadosGenerico: {},
  },

  getters: {
    total: (state) => state.total,
    volume: (state) => state.volume,
    mixProduto: (state) => state.mixProduto,
    mixSKU: (state) => state.mixSKU,
    dadosGenerico: (state) => state.dadosGenerico,
    estoque: (state) => state.estoque,
  },

  actions: {
    update({ dispatch }, payload) {
      return dispatch('$update', payload)
        .catch((error) => {
          if (error?.status !== 404) throw error;

          const { skuId, quantidade } = payload;
          return dispatch('$create', { skuId, quantidade });
        });
    },

    destroy({ dispatch }, payload) {
      return dispatch('$destroy', payload)
        .catch((error) => {
          if (error?.status === 404) return;
          throw error;
        });
    },

    async $changeItem({ dispatch }, { id, skuId, quantidade }) {
      if (!id && quantidade <= 0) return Promise.resolve();

      if (!id) {
        return dispatch('create', { skuId, quantidade });
      }

      if (quantidade > 0) {
        return dispatch('update', { id, skuId, quantidade });
      }

      return dispatch('destroy', { id });
    },

    async changeItem({ dispatch }, { id, skuId, quantidade }) {
      DebouncePromise.stop(changeItemDebounces[skuId]);

      changeItemDebounces[skuId] = new DebouncePromise(200);
      return changeItemDebounces[skuId].start((resolve, reject) => {
        dispatch('$changeItem', { id, skuId, quantidade })
          .then((result) => {
            dispatch('totals');
            resolve(result);
          })
          .catch(reject);
      });
    },

    async totals({ commit }) {
      const { data } = await Api.get(`/${resource}/totals`);
      commit('SET_TOTALS', data);
      return data;
    },

    async condicoesPagamentos() {
      const { data } = await Api.get(`/${resource}/condicoesPagamentos`);
      return data;
    },

    async finish({ commit }, payload) {
      const { data } = await Api.post(`/${resource}/finish`, payload);
      await Api.post(`/${resource}/webhook`, data);

      commit('SET_TOTALS');
      return data;
    },
  },

  mutations: {
    SET_TOTALS(state, payload = {}) {
      state.total = payload.total || null;
      state.volume = payload.volume || null;
      state.mixProduto = payload.mixProduto || null;
      state.mixSKU = payload.mixSku || null;
      state.estoque = payload.estoque || null;

      state.dadosGenerico = payload.dadosGenerico || {};
    },
  },
}));
