<template functional>
  <div class="b2b-grid__column"
  :class="{
    [data.staticClass]: data.staticClass,
    [`b2b-grid__column--cols-${props.mobile || props.cols}`]: props.mobile || props.cols,
    [`b2b-grid__column--cols-tablet-${props.tablet}`]: props.tablet,
    [`b2b-grid__column--cols-laptop-${props.laptop}`]: props.laptop,
    [`b2b-grid__column--cols-desktop-${props.desktop}`]: props.desktop,
    [`b2b-grid__column--cols-ultrawide-${props.ultrawide}`]: props.ultrawide,
  }">
    <slot>{{ data.domProps && data.domProps.textContent }}</slot>
  </div>
</template>

<script>
export default {
  name: 'b2b-grid-column',
  props: {
    cols: [String, Number],
    mobile: [String, Number],
    tablet: [String, Number],
    laptop: [String, Number],
    desktop: [String, Number],
    ultrawide: [String, Number],
  },
};
</script>

<style lang="scss">
@import '../../../styles/break-points.scss';

.b2b-grid__column {
  width: 100%;
  padding: 8px;

  @for $i from 1 through 12 {
    &--cols-#{$i} {
      max-width: #{calc(100% / 12) * $i};
      min-width: #{calc(100% / 12) * $i};
    }
  }

  @each $breakpoint in $breakpoints {
    &--cols-#{$breakpoint} {

      @for $i from 1 through 12 {
        &-#{$i} {
          @include breakpointMin($breakpoint) {
            max-width: #{calc(100% / 12) * $i};
            min-width: #{calc(100% / 12) * $i};
          }
        }
      }
    }
  }
}
</style>
