<template>
  <div class="b2b-list-companies">
    <b2b-divider v-text="$t('resources.sign-in.select-company')" />

    <b2b-list>
      <template v-for="company in $_companies">
        <b2b-list-item :key="company.id" v-on:click="$_onClickItem(company)">
          <template #avatar>
            <b2b-list-item-avatar :src="company.logo" />
          </template>

          <b2b-list-item-title v-text="company.tradingName" />

          <b2b-list-item-subtitle>{{ company.cnpj | document }}</b2b-list-item-subtitle>
        </b2b-list-item>
      </template>
    </b2b-list>
  </div>
</template>

<script>
import B2bDivider from '../core/b2b-divider.vue';
import B2bList from '../core/list/b2b-list.vue';
import B2bListItem from '../core/list/b2b-list-item.vue';
import B2bListItemAvatar from '../core/list/b2b-list-item-avatar.vue';
import B2bListItemTitle from '../core/list/b2b-list-item-title.vue';
import B2bListItemSubtitle from '../core/list/b2b-list-item-subtitle.vue';

export default {
  name: 'b2b-list-companies',

  components: {
    B2bDivider,
    B2bList,
    B2bListItem,
    B2bListItemAvatar,
    B2bListItemTitle,
    B2bListItemSubtitle,
  },

  computed: {
    $_companies() {
      if (!this.$store.getters['session/user']) return [];
      const { companies } = this.$store.getters['session/user'];
      return companies.filter((company) => {
        if (company.roles === 'customer') {
          return company.customers.length !== 0;
        }
        return company;
      });
    },
  },

  methods: {
    $_onClickItem(item) {
      this.$emit('click:item', item);
    },
  },
};
</script>

<style lang="scss">
.b2b-list-companies {
  max-height: 100%;
  overflow-y: auto;
  background-color: inherit;

  &__header {
    top: 0;
    position: sticky;
    background-color: inherit;
  }
}
</style>
