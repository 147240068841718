export default [
  {
    path: '/myApp',
    name: 'MyAppIndex',
    component: () => import(/* webpackChunkName: "customers-index" */ '../../views/myApp.vue'),
    meta: {
      requiresAdmin: true,
      config: {
        header: true,
      },
    },
  }];
