import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('clientes', ({ Api, resource }) => ({
  actions: {
    async index({ dispatch }, payload) {
      const params = payload;
      if (/^[\d|.|/|-]*$/.test(params.s)) {
        params.s = params.s.replace(/[.|/|-]/g, '');
      }

      return dispatch('$index', params);
    },

    async groupedOrders(_, params) {
      const { data, headers } = await Api.get(`${resource}/pedidos`, { params });
      return {
        items: data,
        pages: headers['x-total-page'],
      };
    },

    async getUsersByCustomerId(_, { customerId, ...params }) {
      const { data, headers } = await Api.get(`${resource}/${customerId}/usuarios`, { params });
      return {
        items: data,
        pages: headers['x-total-page'],
      };
    },

    async getCitiesByState(_, params) {
      const { data, headers } = await Api.get(`${resource}/cidades`, { params });
      return {
        items: data,
        pages: headers['x-total-page'],
      };
    },

    async getStates(_, params) {
      const { data } = await Api.get(`${resource}/uf`, { params });
      return {
        items: data,
      };
    },

    async exportCustomers(_, params) {
      const { data } = await Api.get(`${resource}/export`, { params });
      return {
        items: data,
      };
    },

    async getEnvioCustomers(_, { companyId, ...params }) {
      const { data, headers } = await Api.get(`${resource}/${companyId}/envio`, { params });
      return {
        items: data,
        pages: headers['x-total-page'],
        count: headers['x-total-count'],
      };
    },

    async postEnvioCustomers(_, params) {
      const { data } = await Api.post(`${resource}/envio`, params);
      return data;
    },

    async getTotalEnvioCustomers(_, { companyId, ...params }) {
      const { data } = await Api.get(`${resource}/${companyId}/totalenvio`, params);
      return data;
    },
  },
}));
