import {
  BREAKPOINT_MOBILE,
  BREAKPOINT_TABLET,
  BREAKPOINT_LAPTOP,
  BREAKPOINT_DESKTOP,
  BREAKPOINT_ULTRAWIDE,
  currentBreakpoint,
} from '../../utils/breakpoints';

export default function breakpointMixin() {
  return {
    data() {
      return {
        breakpoint: currentBreakpoint(),
      };
    },
    computed: {
      $_isMobile() {
        return this.breakpoint === BREAKPOINT_MOBILE;
      },
      $_isTable() {
        return this.breakpoint === BREAKPOINT_TABLET;
      },
      $_isLaptop() {
        return this.breakpoint === BREAKPOINT_LAPTOP;
      },
      $_isDesktop() {
        return this.breakpoint === BREAKPOINT_DESKTOP;
      },
      $_isUltrawide() {
        return this.breakpoint === BREAKPOINT_ULTRAWIDE;
      },
    },
    methods: {
      $_onResize() {
        this.breakpoint = currentBreakpoint();
      },
    },
    created() {
      window.addEventListener('resize', this.$_onResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.$_onResize);
    },
  };
}
