import UnauthorizedError from '../utils/UnauthorizedError';
import PromiseResolver from './promise-resolver';

function hasUnauthorizedError(error) {
  return error instanceof UnauthorizedError;
}

export default function ActionResolver() {
  return {
    mixins: [
      PromiseResolver(),
    ],

    methods: {
      $_actionResolver({ action, ignoreSessionCatch = false, params = {}, ...promiseResolver }) {
        if (!action) {
          return this.$_promiseRejection('Action is required');
        }

        let promise = this.$_promiseResolver({
          ...promiseResolver,
          promise: this.$store.dispatch(action, params),
          ignoreError: promiseResolver.ignoreError || hasUnauthorizedError,
        });

        if (!ignoreSessionCatch) {
          promise = promise
            .catch((error) => {
              if (!hasUnauthorizedError(error)) throw error;
              return this.$store.dispatch('session/renew')
                .then(() => {
                  this.$_actionResolver({ action, module, params, ...promiseResolver });
                });
            })
            .catch((error) => {
              if (!hasUnauthorizedError(error)) throw error;
              else {
                this.$nextTick(() => {
                  this.$router.push({ name: 'Login' });
                });
              }
            });
        }

        return promise;
      },
    },
  };
}
