<template>
  <b2b-button class="mt--3" @click="$_onClick" flat dense>
    <download-cloud-icon class="mr--3" />

    {{ $t('install-app') }}
  </b2b-button>
</template>

<script>
import { DownloadCloudIcon } from 'vue-feather-icons';

import B2bButton from '../../core/buttons/b2b-button.vue';

export default {
  name: 'b2b-download-button',

  components: {
    DownloadCloudIcon,

    B2bButton,
  },

  methods: {
    $_onClick($event) {
      this.$emit('click', $event);
    },
  },
};
</script>
