<template functional>
  <component
    class="b2b-card"
    :is="props.to ? 'router-link' : 'div'"
    :to="props.to"
    :replace="props.replace"
    v-on="listeners"
    v-show="injections.methods.vShow(data)"
    v-bind="{
      ref: data.ref,
      ...data.attrs,
    }"
    :style="{
      ...data.style,
      maxWidth: injections.methods.toSizeValue(props.maxWidth),
      width: injections.methods.toSizeValue(props.width),
      height: injections.methods.toSizeValue(props.height),
    }"
    :class="{
      ...data.class,
      [data.staticClass]: !!data.staticClass,
      'b2b-card--row': props.row,
      'b2b-card--flat': props.flat,
      'b2b-card--fill-height': props.fillHeight,
      'b2b-card--fill-width': props.fillWidth,
      'b2b-card--clickable': !!listeners.click || props.to,
      'b2b-card--hoverable': !!(listeners.click || props.to || props.hoverable),
      'b2b-card--inactive': props.inactive,
    }"
  >
    <slot>{{ data.domProps && data.domProps.textContent }}</slot>
  </component>
</template>

<script>
import { toSizeValue } from '../../../utils/css-variables';

export default {
  name: 'b2b-card',
  props: {
    maxWidth: [String, Number],
    width: [String, Number],
    height: [String, Number],
    fillHeight: Boolean,
    fillWidth: Boolean,
    to: [String, Object],
    replace: Boolean,
    row: Boolean,
    inactive: Boolean,
    flat: Boolean,
  },
  inject: {
    methods: {
      default: {
        toSizeValue,
        vShow({ directives = [] }) {
          const vShow = directives.find((d) => d.rawName === 'v-show');
          return vShow ? vShow.value : true;
        },
      },
    },
  },
};
</script>

<style lang="scss">
.b2b-card {
  $root: &;

  max-width: 100%;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  box-shadow: $cardBoxShadow;
  border-radius: $cardBorderRadius;
  background-color: $colorBackgroundCard;

  transition: box-shadow 0.3s;

  &--row {
    flex-direction: row;
  }

  &--fill-height {
    min-height: 100%;
    max-height: 100%;
  }

  &--fill-width {
    width: 100%;
  }

  &--inactive {
    text-decoration: line-through;
    opacity: 0.5;
  }

  &--clickable {
    cursor: pointer;
  }

  &--flat {
    box-shadow: none;
  }

  &--hoverable {
    &:hover {
      box-shadow:
        0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
  }

  > :first-child {
    border-top-left-radius: $cardBorderRadius;
    border-top-right-radius: $cardBorderRadius;
  }

  > :last-child {
    border-bottom-left-radius: $cardBorderRadius;
    border-bottom-right-radius: $cardBorderRadius;
  }
}
</style>
