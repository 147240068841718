export default function ModelMixin(valueType, prop = 'value', event = 'input') {
  if (!valueType) {
    throw new Error('Value type is required in ModelMixin');
  }

  return {
    props: { [prop]: valueType },
    model: { prop, event },
    computed: {
      $_model: {
        get() {
          if (!this[prop] && valueType === Array) return [];
          if (!this[prop] && valueType === Object) return {};
          return this[prop];
        },
        set(value) {
          if (valueType === Array || Array.isArray(value)) {
            this.$emit(event, [...value]);
          } else if (valueType === Object || typeof value === 'object') {
            this.$emit(event, JSON.parse(JSON.stringify(value)));
          } else {
            this.$emit(event, value);
          }
        },
      },
    },
  };
}
