export default function SyncPropsMixin(props) {
  if (typeof props !== 'object') {
    throw new Error('Sync Props Mixin requires a object in first param');
  }

  const computed = {};
  Object.entries(props).forEach(([key, options]) => {
    const updateProp = `update:${key}`;

    const type = options.type || options;

    computed[`$_${key}Sync`] = {
      get() {
        if (type === Array) {
          if (!Array.isArray(this[key])) {
            return [];
          }
        }

        return this[key];
      },

      set(value) {
        if (Array.isArray(value)) {
          this.$emit(updateProp, [...value]);
        } else if (typeof value === 'object') {
          this.$emit(updateProp, { ...value });
        } else {
          this.$emit(updateProp, value);
        }
      },
    };
  });

  return {
    props,
    computed,
  };
}
