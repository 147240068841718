var render = function render(_c,_vm){return _c('div',{staticClass:"b2b-grid__column",class:{
  [_vm.data.staticClass]: _vm.data.staticClass,
  [`b2b-grid__column--cols-${_vm.props.mobile || _vm.props.cols}`]: _vm.props.mobile || _vm.props.cols,
  [`b2b-grid__column--cols-tablet-${_vm.props.tablet}`]: _vm.props.tablet,
  [`b2b-grid__column--cols-laptop-${_vm.props.laptop}`]: _vm.props.laptop,
  [`b2b-grid__column--cols-desktop-${_vm.props.desktop}`]: _vm.props.desktop,
  [`b2b-grid__column--cols-ultrawide-${_vm.props.ultrawide}`]: _vm.props.ultrawide,
}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.data.domProps && _vm.data.domProps.textContent))]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }