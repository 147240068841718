<template>
  <b2b-menu v-model="isActive" class="b2b-bell-menu">
    <template #activator="{ on }">
      <b2b-tooltip :label="$t('resources.menu.tooltip.notifications')">
        <template #activator>
          <b2b-button-icon v-on="on">
            <div :class="menus.qtdNotifications > 0 ? 'b2b-button__icon__item' : ''">
              <bell-icon />
              <label
                class="b2b-button__icon__item__label"
                v-if="menus.qtdNotifications > 0">
                {{ menus.qtdNotifications }}
              </label>
            </div>
          </b2b-button-icon>
        </template>
      </b2b-tooltip>
    </template>
    <div class="notifications__item">
      <b2b-list v-if="menus.notifications.length > 0">
        <b2b-list-item
          v-for="item in $_menu"
          :key="item.id"
          @click="$_updateMarkReadNotification(item)">
          <b2b-list-item-title v-text="item.title" />
        </b2b-list-item>
      </b2b-list>
    </div>
  </b2b-menu>
</template>

<script>
import { BellIcon } from 'vue-feather-icons';
import ActionResolver from '../../mixins/action-resolver';

import B2bMenu from '../core/b2b-menu.vue';
import B2bList from '../core/list/b2b-list.vue';
import B2bListItem from '../core/list/b2b-list-item.vue';
import B2bListItemTitle from '../core/list/b2b-list-item-title.vue';
import B2bButtonIcon from '../core/buttons/b2b-button-icon.vue';
import B2bTooltip from '../core/b2b-tooltip.vue';

export default {
  name: 'b2b-bell-menu',

  components: {
    B2bMenu,
    B2bList,
    B2bListItem,
    B2bListItemTitle,
    B2bButtonIcon,
    BellIcon,
    B2bTooltip,
  },

  mixins: [
    ActionResolver(),
  ],

  data() {
    return {
      isActive: false,

      menus: {
        notifications: [],
        qtdNotifications: 0,
      },
    };
  },

  async created() {
    if (this.$_isCustomer && this.$_customer) {
      const { customerId } = this.$_customer;
      await this.$_getNotifications(customerId);
    }
  },

  computed: {
    $_menu() {
      let menu = [];

      if (this.$store.getters['session/isCustomer']) {
        menu = menu.concat(this.menus.notifications);
      }

      return menu;
    },

    $_isCustomer() {
      return this.$store.getters['session/isCustomer'];
    },

    $_customer() {
      return this.$store.getters['session/customer'];
    },
  },

  methods: {
    $_onClickSignOut() {
      this.$_actionResolver({
        action: 'session/logout',
        ignoreError: true,
      })
        .then(() => {
          this.$router.replace({ name: 'Login' });
        });
    },

    async $_getNotifications(customerId) {
      let qteNotifications = 0;
      this.menus.notifications = [];

      const messages = await this.$_actionResolver({
        action: 'messages/getMessagesNotifications',
        params: { id: customerId },
      });

      messages.forEach((item) => {
        const validate = Boolean(item.ativo) && (Boolean(item.popup) === false);
        if (validate) {
          if (Boolean(item.lido) === false) qteNotifications += 1;
          this.menus.notifications.push({
            title: item.texto,
            id: item.id,
            lido: item.lido,
            permitirLido: item.permitirLido,
          });
        }
      });

      this.menus.qtdNotifications = qteNotifications;
    },

    $_setReadNotification(item) {
      this.menus.qtdNotifications -= 1;
      this.menus.notifications.forEach((value, index) => {
        if (value.id === item.id) {
          this.menus.notifications[index].lido = 1;
        }
      });
    },

    async $_updateMarkReadNotification(item) {
      if (Boolean(item.permitirLido) === false || item.lido === undefined) return;

      if (Boolean(item.lido) === false) {
        const { customerId } = this.$store.state.session.customer;
        const result = await this.$_actionResolver({
          action: 'messages/updateMarkReadNotification',
          params: {
            id: item.id,
            form: {
              lido: 1,
              clienteUsuarioId: customerId,
            },
          },
        });

        if (Boolean(result.lido) === true) this.$_setReadNotification(item);
      }
    },
  },

  watch: {
    $route() {
      this.isActive = false;
    },

    async $_isCustomer() {
      if (this.$_isCustomer && this.$_customer) {
        const { customerId } = this.$_customer;
        await this.$_getNotifications(customerId);
      }
    },

    async $_customer() {
      if (this.$_isCustomer && this.$_customer) {
        const { customerId } = this.$_customer;
        await this.$_getNotifications(customerId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .b2b-button__icon:hover {
    color: #d8d8d8 !important;
  }

  .b2b-bell-menu {
    display: flex;

    &__avatar {
      cursor: pointer;
    }
  }

  .router-link-active {
    background-color: #fff !important;

    &:hover {
      background-color: rgba(0, 0, 0, 0.24) !important;
    }
  }

  .b2b-button__icon__item {
    display: flex;
    margin-left: 5px;

    .b2b-button__icon__item__label {
      padding: 0px;
      margin-left: -13px;
      margin-top: -19px;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      background-color: var(--main-color--primary);
      border: 1px solid #fff;
      color: #fff;
      font-weight: bold;
    }
  }

  .notifications__item {
    .list__item__content {
      padding: 7px 0 !important;
    }

    .list__item__title {
      font-size: 0.8rem !important;
      white-space: normal !important;
    }

    .list__item {
      &:hover {
        background-color: rgba(0, 0, 0, 0.10) !important;
        cursor: pointer;
      }
    }
  }
</style>
