<template>
  <component
    v-on="$_listeners"
    class="b2b-button__icon"
    :is="to ? 'router-link' : 'button'"
    :to="to"
    :type="!to && type"
    :disabled="$_disabled"
    :class="{
      'b2b-button__icon--active': active || activeButton,
      'b2b-button__icon--active-exact': exact,
      'b2b-button__icon--small': small,
      'b2b-button__icon--flat': flat,
    }"
  >
    <b2b-loading v-if="loading" />

    <template v-else>
      <slot />

      <slot name="info" />
    </template>
  </component>
</template>

<script>
import ButtonMixin from '../../../mixins/components/button-mixin';

import B2bLoading from '../b2b-loading.vue';

export default {
  name: 'b2b-button-icon',
  mixins: [
    ButtonMixin(),
  ],
  props: {
    activeButton: Boolean,
    small: Boolean,
    flat: Boolean,
  },
  components: {
    B2bLoading,
  },
};
</script>

<style lang="scss">
$colorPrimary: var(--main-color--primary);
$colorPrimaryText: var(--main-color--primary-text);

.b2b-button__icon {
  position: relative;
  width: $buttonSize;
  height: $buttonSize;
  min-width: $buttonSize;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  cursor: pointer;
  color: $colorMainPrimary;

  border: none;
  border-radius: $buttonSize;
  border: 1px solid currentColor;

  transition: all 0.2s;

  & + & {
    margin-left: 8px;
  }

  > * {
    pointer-events: none;
  }

  &:hover {
    opacity: 0.6;
    color: $colorPrimary;
    box-shadow: $boxShadowDefault;
    background-color: $colorPrimary;

    > svg,
    > span {
      color: $colorPrimaryText;
    }
  }

  &:active,
  &--active,
  &.router-link-active:not(&--active-exact),
  &--active-exact.router-link-exact-active {
    color: $colorPrimary;
    opacity: 1 !important;
    background-color: $colorPrimary;

    > svg,
    > span {
      color: $colorPrimaryText;
    }
  }

  &--small {
    width: $buttonSmallSize;
    height: $buttonSmallSize;
    min-width: $buttonSmallSize;
    border-radius: $buttonSmallSize;
  }

  &--flat {
    border: none;
    min-width: 0;
    color: $colorPrimary;

    &:hover {
      box-shadow: none;
      background: none;
      opacity: 1 !important;

      > svg {
        color: $colorPrimary;
      }
    }
  }

  &:disabled {
    color: $colorMainSecondary;

    &:hover,
    &:active {
      box-shadow: none;
      opacity: 0.6 !important;
      color: $colorMainSecondary;
      background-color: $colorMainSecondary;

      > svg,
      > span {
        color: $colorMainPrimary;
      }
    }
  }
}
</style>
