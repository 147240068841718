export default {
  path: '/shopping-cart',
  name: 'ShoppingCart',
  component: () => import(/* webpackChunkName: "shopping-cart" */ '../../views/ShoppingCart.vue'),
  meta: {
    requiresCustomer: true,
    config: {
      header: true,
      back: { name: 'Main' },
    },
  },
};
