<template>
  <b2b-overlay v-model="isActive" role="dialog" full-screen>
    <template v-slot:activator="{ on, attrs }">
      <b2b-input readonly clearable
        v-bind="attrs"
        v-on="on"
        :value="$_value"
        :disabled="!state"
        @click:clear="$_onClickClear"
        :label="$t('resources.customers.city')"
      />
    </template>
    <b2b-card width="100vw" height="100vh" fill-height>
      <b2b-input-search v-model="query.search" clearable flat hide-code-search />

      <b2b-card-text scrollable>
        <h3 class="mt--3 mx--3" v-text="title" />

        <b2b-grid>
          <b2b-grid-row wrap>
            <template v-for="(city, index) in cities">
              <b2b-grid-column cols="12" tablet="6" :key="index">
                <b2b-checkbox
                  v-model="$_model"
                  :value="city"
                  :label="city"
                  @change="$_onChange($event, city)" />
              </b2b-grid-column>
            </template>
          </b2b-grid-row>
        </b2b-grid>
      </b2b-card-text>

      <b2b-card-actions>
        <b2b-pagination v-model="query.page" :pages="pages" small />

        <b2b-spacer />

        <b2b-button @click.stop="$_onClickOk" v-text="$t('labels.ok')" dense />
      </b2b-card-actions>
    </b2b-card>
  </b2b-overlay>
</template>

<script>
import ActivableMixin from '../../mixins/base/activable-mixin';
import ModelMixin from '../../mixins/base/model-mixin';
import ActionResolver from '../../mixins/action-resolver';

import B2bOverlay from '../core/b2b-overlay.vue';
import B2bCard from '../core/card/b2b-card.vue';
import B2bCardText from '../core/card/b2b-card-text.vue';
import B2bCardActions from '../core/card/b2b-card-actions.vue';
import B2bInputSearch from '../core/form/b2b-input-search.vue';
import B2bGrid from '../core/grid/b2b-grid.vue';
import B2bGridRow from '../core/grid/b2b-grid-row.vue';
import B2bGridColumn from '../core/grid/b2b-grid-column.vue';
import B2bCheckbox from '../core/form/b2b-checkbox.vue';
import B2bPagination from '../core/b2b-pagination.vue';
import B2bSpacer from '../core/b2b-spacer.vue';
import B2bButton from '../core/buttons/b2b-button.vue';
import B2bInput from '../core/form/b2b-input.vue';

import DialogCityModelEnum from './b2b-dialog-cities-dialog-model';

export default {
  name: 'b2b-dialog-cities',

  components: {
    B2bOverlay,
    B2bCard,
    B2bCardText,
    B2bCardActions,
    B2bInputSearch,
    B2bGrid,
    B2bGridRow,
    B2bGridColumn,
    B2bCheckbox,
    B2bPagination,
    B2bSpacer,
    B2bButton,
    B2bInput,
  },

  mixins: [
    ActivableMixin('active'),

    ModelMixin(Array),

    ActionResolver(),
  ],

  props: {
    title: String,
    tagTypeId: [String, Number],
    state: String,
    queryModel: {
      type: String,
      default: DialogCityModelEnum.QueryAllCities,
    },
  },

  data() {
    return {
      cities: [],
      pages: 1,
      customerCitiesResult: [],
      query: {
        page: 1,
        search: null,
        startLetter: null,
      },
    };
  },

  computed: {
    $_value() {
      if (Array.isArray(this.$_model) && this.$_model.length > 0) {
        return this.$_model.length === 1 ? this.$_model[0] : `${this.$_model.length} Selecionados`;
      }
      return '';
    },

    $_company() {
      return this.$store.getters['session/company'] || {};
    },
  },

  methods: {
    async $_load() {
      if (this.ignoreNextLoad) {
        this.ignoreNextLoad = false;
        return;
      }

      if (this.queryModel === DialogCityModelEnum.QueryAllCities) {
        const result = await this.$_actionResolver({
          action: 'customers/getCitiesByState',
          params: {
            page: this.query.page,
            s: this.query.search,
            uf: this.state,
          },
        });

        const { items, pages } = result;
        this.pages = pages;
        this.cities = items.map(({ cidade }) => cidade);
      } else if (this.queryModel === DialogCityModelEnum.QueryCustomerCities) {
        const { id } = this.$_company;
        const result = await this.$_actionResolver({
          action: 'customers/getEnvioCustomers',
          params: {
            ignoreInvitationType: true,
            companyId: id,
            page: this.query.page,
            limit: 24,
            getCities: true,
          },
        });

        const { pages } = result;
        this.pages = pages;

        this.customerCitiesResult = result;
        this.$_customerCityFilter();
      }
    },
    $_customerCityFilter() {
      const { items, pages } = this.customerCitiesResult;
      this.pages = pages;

      const newCities = [];
      items.forEach(({ cidade }) => {
        if (!newCities.includes(cidade)) {
          if (!this.query.search) {
            newCities.push(cidade);
          } else if (cidade.toUpperCase().startsWith(this.query.search.toUpperCase())) {
            newCities.push(cidade);
          }
        }
      });

      newCities.sort();
      this.cities = newCities;
    },

    $_reload() {
      this.pages = 1;

      if (this.query.page !== 1) {
        this.query.page = 1;
        this.ignoreNextLoad = true;
      }

      if (this.query.search !== null) {
        this.query.search = null;
        this.ignoreNextLoad = true;
      }

      this.$nextTick(() => {
        this.$_load();
      });
    },

    $_onClickOk() {
      this.isActive = false;
    },

    $_onChange($event, $city) {
      this.$emit('change', { $event, $city });
    },

    $_onClickClear() {
      this.$_model = '';
    },
  },

  watch: {
    isActive(active) {
      if (active) this.$_reload();
    },

    'query.page': function onWatchQueryPage() {
      this.$_load();
    },

    'query.search': function onWatchQuerySearch() {
      if (this.query.page !== 1) {
        this.query.page = 1;
      } else if (this.queryModel === DialogCityModelEnum.QueryCustomerCities) {
        this.$_customerCityFilter();
      } else {
        this.$_load();
      }
    },
  },
};
</script>
