<template>
  <div class="b2b-menu">
    <slot name="activator" :on="activator" />
    <transition-bounce>
      <b2b-card
        v-show="isActive"
        ref="card"
        width="256"
        maxWidth="246px"
        class="b2b-menu__content"
        :style="{
          marginLeft: '-190px',
          top: '85px'
        }">
        <slot />
      </b2b-card>
    </transition-bounce>
  </div>
</template>

<script>
import ClickOutsideMixin from '../../mixins/base/click-outside-mixin';

import TransitionBounce from './transition/b2b-transition-bounce.vue';
import B2bCard from './card/b2b-card.vue';

export default {
  name: 'b2b-menu',

  mixins: [
    ClickOutsideMixin({
      ref: 'card',
    }),
  ],

  components: {
    TransitionBounce,
    B2bCard,
  },

  methods: {
    $_updateMenuPosition() {
      if (!this.lastTarget) {
        return;
      }

      this.$refs.card.style.top = `${this.lastTarget.offsetTop + this.lastTarget.offsetHeight + 4}px`;

      const widht = this.$refs.card.clientWidth + 4;

      let left = this.lastTarget.offsetLeft;
      if (left + widht > document.body.clientWidth) {
        left = document.body.clientWidth - widht;
      }

      this.$refs.card.style.left = `${left}px`;

      let maxHeight = null;
      if ((this.$refs.card.offsetTop + this.$refs.card.clientHeight) > window.innerHeight) {
        maxHeight = window.innerHeight - this.$refs.card.offsetTop - 4;
      }

      this.$refs.card.style.maxHeight = maxHeight ? `${maxHeight}px` : null;
    },

    $_onActivatorClick($event) {
      $event.preventDefault();
      $event.stopPropagation();

      this.isActive = !this.isActive;
      this.lastTarget = $event.target;

      this.$nextTick(() => {
        this.$_updateMenuPosition();
      });
    },

    $_onResize() {
      this.$_updateMenuPosition();
    },

    $_onKeyPress($event) {
      if ($event.key === 'Escape') {
        this.isActive = false;
      }
    },
  },

  watch: {
    isActive(active) {
      if (active) {
        window.addEventListener('keyup', this.$_onKeyPress);
      } else {
        window.removeEventListener('keyup', this.$_onKeyPress);
      }
    },
  },

  created() {
    this.activator = { click: this.$_onActivatorClick };

    window.addEventListener('resize', this.$_onResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.$_onResize);
    window.removeEventListener('keyup', this.$_onKeyPress);
  },
};
</script>

<style lang="scss">
.b2b-menu {
  display: inline-block;
  white-space: nowrap;

  &__content {
    z-index: 1000;
    position: absolute;

    overflow-y: auto;
  }
}
</style>
