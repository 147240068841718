import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('ftp', ({ Api, resource }) => ({
  actions: {
    async sendToCloud() {
      const { data } = await Api.post(`${resource}/sendToCloud`);

      return data;
    },
  },
}));
