export default function DebounceMixin(valueType = String) {
  return {
    props: {
      value: valueType,
    },

    data() {
      return {
        model: this.value,
        timeout: null,
      };
    },

    watch: {
      model(value) {
        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          if (this.value === value) return;
          this.$emit('input', value);
        }, 1000);
      },

      value(value) {
        this.model = value;
      },
    },
  };
}
