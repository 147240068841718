import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('mensagens', ({ Api, resource }) => ({
  actions: {
    async getClientWithUser(_, params) {
      const { data, headers } = await Api.get(`${resource}/clientes`, { params });
      return {
        items: data,
        pages: headers['x-total-page'],
        count: headers['x-total-count'],
      };
    },
    async createMessageUser(_, params) {
      const { data } = await Api.post(`${resource}/mensagensUsuarios`, params);
      return data;
    },
    async deleteMessageUser(_, { id }) {
      const { data } = await Api.delete(`${resource}/${id}/mensagensUsuarios`);
      return data;
    },
    async getMessageUser(_, { id }) {
      const { data } = await Api.get(`${resource}/${id}/mensagensUsuarios`);
      return data;
    },
    async updateMessageUser(_, params) {
      const { data } = await Api.put(`${resource}/${params.id}/mensagensUsuarios`, params);
      return data;
    },
    async getMessagesNotifications(_, { id }) {
      const { data } = await Api.get(`${resource}/${id}/notifications`);
      return data;
    },
    async updateMarkReadNotification(_, { id, form }) {
      const { data } = await Api.put(`${resource}/${id}/notifications`, form);
      return data;
    },
    async updateMessage(_, { id, form }) {
      const { data } = await Api.put(`${resource}/${id}`, form);
      return data;
    },
  },
}));
