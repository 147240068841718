let inputCount = 0;

export default function InputMixin(inputType = 'text', extension = false) {
  const common = {
    props: {
      type: {
        type: String,
        default: inputType,
      },
      name: String,
      label: String,
      placeholder: String,
      required: Boolean,
      disabled: Boolean,
      readonly: Boolean,
      hidden: Boolean,
      autofocus: Boolean,
      min: null,
      max: null,
    },

    mounted() {
      if (!this.autofocus) return;

      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
  };

  if (extension) {
    return {
      props: {
        id: String,
        ...common.props,
      },

      computed: {
        $_bind() {
          return this.$props;
        },
      },
    };
  }

  return {
    props: {
      id: {
        type: String,
        default() {
          inputCount += 1;
          return `input-${inputCount}`;
        },
      },
      ...common.props,
    },

    computed: {
      $_layoutBind() {
        return {
          id: this.id,
          value: this.value,
          label: this.label,
          hidden: this.hidden,
          disabled: this.disabled,
        };
      },

      $_inputBind() {
        return {
          id: this.id,
          type: this.type,
          name: this.name,
          required: this.required,
          disabled: this.disabled,
          readonly: this.readonly,
          placeholder: this.placeholder || this.label,
          class: 'b2b-input-layout__input',
          min: this.min,
          max: this.max,
        };
      },
    },
  };
}
