<template>
  <b2b-overlay v-model="isActive" role="dialog">
    <b2b-card width="460" fill-height>
      <div class="b2b-export-customers--header">
        <b2b-card-title v-text="$_title" row />
        <b2b-button @click.stop="$_onClickCancel" v-text="$t('x')" class="message--close" dense />
      </div>

      <div class="b2b-export-customers--content">
        <b2b-checkbox
          v-model="checkbox"
          :value="checkbox"
          :label="$t('resources.customers.filter-date-access')"
          @change="$_onChangeCheckBox($event)" />

        <div class="b2b-export-customers--form" v-if="checkbox">
          <b2b-input-date-picker-export required
            v-model="range.start"
            :label="$t('labels.date-from')"
            :min="minRangeDate"
            :max="range.end"
            clearable
          />

          <b2b-input-date-picker-export required
            v-model="range.end"
            :label="$t('labels.date-to')"
            :min="range.start"
            :max="maxRangeDate"
            clearable
          />
        </div>
      </div>

      <div class="b2b-export-customers--footer">
        <b2b-button
          class="b2b-export-customers-button"
          @click.stop="$_onClickOk"
          v-text="titleButtonExport"
          :disabled="disabledButtonExport"
          :loading="loadingButtonExport"
          dense />
      </div>
    </b2b-card>
  </b2b-overlay>
</template>

<script>
import { jsonToExcel } from '../../utils/export';

import ActionResolver from '../../mixins/action-resolver';
import ActivableMixin from '../../mixins/base/activable-mixin';

import B2bOverlay from '../core/b2b-overlay.vue';
import B2bCard from '../core/card/b2b-card.vue';
import B2bCardTitle from '../core/card/b2b-card-title.vue';
import B2bButton from '../core/buttons/b2b-button.vue';

import B2bCheckbox from '../core/form/b2b-checkbox.vue';
import B2bInputDatePickerExport from '../core/form/b2b-input-date-picker-export.vue';

export default {
  name: 'b2b-dialog-export-customers',

  components: {
    B2bOverlay,
    B2bCard,
    B2bCardTitle,
    B2bCheckbox,
    B2bInputDatePickerExport,
    B2bButton,
  },

  data() {
    return {
      checkbox: false,
      disabledButtonExport: false,
      titleButtonExport: this.$t('labels.exportCustomers'),
      loadingButtonExport: false,
      range: {
        end: '',
        start: '',
      },
      minRangeDate: null,
      maxRangeDate: null,
    };
  },

  mixins: [
    ActionResolver(),
    ActivableMixin(),
  ],

  props: {
    dataForm: Object,
    title: String,
  },

  computed: {
    $_title() {
      return this.title || this.$tc('labels.exportCustomers');
    },
  },

  watch: {
    'range.start': function watchRangeStart(value) {
      this.maxRangeDate = new Date(
        new Date(value).setDate(new Date(value).getDate() + 31),
      );
    },

    'range.end': function watchRangeEnd(value) {
      this.minRangeDate = new Date(new Date(value).setDate(new Date(value).getDate() - 31));
    },
  },

  methods: {
    $_onClickCancel() {
      this.isActive = false;
      this.$_changeButtonExport();
      this.checkbox = false;
      this.range = {
        end: '',
        start: '',
      };
    },

    async $_onClickOk() {
      this.$_changeButtonExport(true, this.$t('labels.exportCustomersLoading'));
      const { actionsParams, headers } = this.dataForm;

      let params = actionsParams;
      if (this.checkbox) {
        params = {
          ...actionsParams,
          range: {
            start: this.range.start,
            end: this.range.end,
          },
        };
      }

      return this.$_actionResolver({
        action: 'customers/exportCustomers',
        params,
        loading: 'loading',
      }).then(({ items }) => {
        jsonToExcel(this.$t('resources.customers.excel.title'), items, headers);
        this.$_changeButtonExport();
        this.$_onClickCancel();
      });
    },

    $_onChangeCheckBox($event) {
      this.checkbox = $event;
    },

    $_changeButtonExport(action = false, text = this.$t('labels.exportCustomers')) {
      this.loadingButtonExport = action;
      this.disabledButtonExport = action;
      this.titleButtonExport = text;
    },
  },
};
</script>

<style scoped>
.b2b-export-customers--header {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.b2b-export-customers--content {
  border-top: 1px solid #a5a5a5;
  display: block;
  min-width: 100%;
  min-height: 48px;
  padding: 7px 25px 0 25px;
}

.b2b-export-customers--form {
  margin-top: 10px;
}

.b2b-export-customers--footer {
  border-top: 1px solid #a5a5a5;
  display: block;
  width: 100%;
  padding-right: 34px;
  padding-left: 20px;
}

.b2b-export-customers--footer button {
  width: 100%;
}

.b2b-export-customers-button {
  display: flex;
  flex-direction: row-reverse;
}
</style>

<style>
.b2b-export-customers-button .b2b-loader {
  margin-right: 10px;
  width: 20px !important;
  height: 20px !important;
}
</style>
