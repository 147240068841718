import { toAmericanString } from './format-date';

class DateRange {
  constructor(start, end) {
    this.$start = new Date(start);
    this.$end = new Date(end);

    const currentDate = new Date();
    this.start = start || currentDate;
    this.end = end || currentDate;
  }

  get start() {
    return this.$start;
  }

  set start(value) {
    const start = value > this.$end ? this.$end : value;

    this.$start = new Date(start);
    // this.$start.setHours(0);
    // this.$start.setMinutes(0);
    // this.$start.setSeconds(0);
  }

  get end() {
    return this.$end;
  }

  set end(value) {
    const end = value < this.$start ? this.$start : value;

    this.$end = new Date(end);
    // this.$end.setHours(23);
    // this.$end.setMinutes(59);
    // this.$end.setSeconds(59);
  }

  toString() {
    if (!this.$start && !this.$end) return undefined;

    const result = [];

    if (this.$start) {
      result.push(toAmericanString(this.$start));
    }

    if (this.$end) {
      result.push(toAmericanString(this.$end));
    }

    return result.join('~');
  }

  toDateTimeString() {
    if (!this.$start && !this.$end) return undefined;

    const result = [];

    if (this.$start) {
      const start = new Date(this.$start);
      start.setUTCHours(0);
      start.setUTCMinutes(0);
      start.setUTCSeconds(0);

      result.push(start.toJSON());
    }

    if (this.$end) {
      const end = new Date(this.$end);
      end.setUTCHours(23);
      end.setUTCMinutes(59);
      end.setUTCSeconds(59);

      result.push(end.toJSON());
    }

    return result.join('~');
  }

  toJSON() {
    return this.toString();
  }

  static isDateRange(value) {
    return value instanceof DateRange;
  }

  static fromString(string) {
    if (typeof string !== 'string') {
      return new DateRange();
    }

    return new DateRange(...string.split('~'));
  }

  static isEquals(dateRangeA, dateRangeB) {
    return DateRange.toString(dateRangeA) === DateRange.toString(dateRangeB);
  }
}

export default DateRange;
