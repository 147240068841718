<template functional>
  <div class="b2b-card__actions"
    :class="{
      ...data.staticClass,
      'b2b-card__actions--centered': props.centered,
      'b2b-card__actions--justify-center': props.justifyCenter,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'b2b-card-actions',
  props: {
    centered: Boolean,
    justifyCenter: Boolean,
  },
};
</script>

<style lang="scss">
.b2b-card__actions {
  padding: 8px 16px;

  flex: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  &--centered {
    align-items: center;
  }

  &--justify-center {
    justify-content: center;
  }
}
</style>
