<template>
  <div class="b2b-loader">Loading...</div>
</template>

<script>
export default {
  name: 'b2b-loading',
};
</script>

<style lang="scss">
$size: 32px;

.b2b-loader {
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid rgba(0, 0, 0, 0.6);
  animation: b2b-loading 1.1s infinite linear;

  &,
  &::after {
    width: $size;
    height: $size;
    border-radius: calc($size / 2);
  }
}

@keyframes b2b-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
