<template>
  <b2b-overlay v-model="isActive" role="dialog">
    <b2b-card width="512" fill-height>
      <b2b-list-companies @click:item="$_onClickItem" />
    </b2b-card>
  </b2b-overlay>
</template>

<script>
import ActivableMixin from '../../mixins/base/activable-mixin';
import ActionResolver from '../../mixins/action-resolver';

import B2bOverlay from '../core/b2b-overlay.vue';
import B2bCard from '../core/card/b2b-card.vue';
import B2bListCompanies from '../lists/b2b-list-companies.vue';

export default {
  name: 'b2b-dialog-select-company',

  mixins: [
    ActivableMixin(),
    ActionResolver(),
  ],

  components: {
    B2bOverlay,
    B2bCard,
    B2bListCompanies,
  },

  computed: {
    $_currentCompany() {
      return this.$store.getters['session/company'] ?? {};
    },
  },

  methods: {
    $_reload() {
      window.location.href = window.location.origin;
    },

    $_onClickItem(company) {
      if (company.id === this.$_currentCompany.id) {
        this.isActive = false;
        return;
      }

      this.$_actionResolver({
        action: 'session/setCompany',
        params: company.id,
      })
        .then(() => {
          if (this.$store.getters['session/isAdmin']) {
            this.$_reload();
          } else if (!Array.isArray(company.customers) || company.customers.length === 0) {
            this.$hub.emit('dialog:error', new Error(this.$t('errors.customer-not-found')));
          } else if (company.customers.length === 1) {
            this.$_setCustomer(company.customers[0]);
          } else {
            this.$_reload();
          }
        })
        .catch((error) => {
          this.$emit('error', error);
        })
        .finally(() => {
          this.isActive = false;
        });
    },

    $_setCustomer(customer) {
      this.$_actionResolver({
        action: 'session/setCustomer',
        params: customer.userCustomerId,
      })
        .then(this.$_reload);
    },
  },
};
</script>
