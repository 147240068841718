<template>
  <b2b-overlay v-model="isActive" role="dialog">
    <template #activator>
      <component :is="$_component" @click="$_onClick" />
    </template>

    <b2b-card max-width="340">
      <b2b-card-title class="flex--column align--center">
        <img src="/img/icons/apple-touch-icon-76x76.png" width="64" height="64" />
        <div>{{ $t('install-title') }}</div>
      </b2b-card-title>

      <b2b-card-text class="mt--2">
        {{ $t('install-message') }}
      </b2b-card-text>

      <b2b-card-actions centered justify-center>
        <div class="b2b-download--actions--item">
          <span>{{ $t('install-justTap') }}</span>

          <upload-icon class="mx--2 primary--text" size="19" />
        </div>

        <div class="b2b-download--actions--item">
          <span class="mr--2">{{ $t('install-then') }}</span>

          <span class="primary--text">'{{ $t('install-homeScreen') }}'</span>
        </div>
      </b2b-card-actions>
    </b2b-card>
  </b2b-overlay>
</template>

<script>
import { UploadIcon } from 'vue-feather-icons';

import B2bOverlay from '../../core/b2b-overlay.vue';
import B2bCard from '../../core/card/b2b-card.vue';
import B2bCardTitle from '../../core/card/b2b-card-title.vue';
import B2bCardText from '../../core/card/b2b-card-text.vue';
import B2bCardActions from '../../core/card/b2b-card-actions.vue';

import B2bDownloadButton from './b2b-download-button.vue';
import B2bDownloadListItem from './b2b-download-list-item.vue';

function isStandalone($route) {
  if (window.navigator.standalone) return true;
  return $route.query.standalone === 'true';
}

export default {
  name: 'b2b-download',

  components: {
    UploadIcon,

    B2bOverlay,
    B2bCard,
    B2bCardTitle,
    B2bCardText,
    B2bCardActions,

    B2bDownloadButton,
    B2bDownloadListItem,
  },

  props: {
    button: Boolean,
  },

  data() {
    return {
      isActive: false,
    };
  },

  computed: {
    $_component() {
      if (window.navigator.standalone) return null;
      if (this.button) return B2bDownloadButton;
      return B2bDownloadListItem;
    },
  },

  methods: {
    $_onClick() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      this.isIos = /iphone|ipad|ipod/.test(userAgent);

      if (this.isIos && !isStandalone(this.$route)) {
        this.$_isntallIos();
      } else {
        this.$_installAndroid();
      }
    },

    async $_installAndroid() {
      if (!this.$store.state.swPrompt) return;

      this.$store.state.swPrompt.prompt();

      const result = await this.$store.state.swPrompt.userChoice;
      if (result.outcome === 'accepted') {
        console.log('User accepted the install prompt'); // eslint-disable-line
      } else {
        console.log('User dismissed the install prompt'); // eslint-disable-line
      }
    },

    $_isntallIos() {
      this.isActive = true;
    },
  },
};
</script>

<style lang="scss">
.b2b-download {
  &--actions--item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
