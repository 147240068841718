function hasIgnoreError(ignoreError, error) {
  if (typeof ignoreError === 'function') {
    return ignoreError(error);
  }

  return ignoreError;
}

export default function PromiseResolver() {
  return {
    methods: {
      $_promiseUpdateLoading(loading, value) {
        const path = loading.split('.');
        const key = path.pop();

        const object = path.reduce((obj, p) => obj[p], this);
        this.$set(object, key, value);
      },

      $_promiseRejection(error) {
        this.$hub.emit('dialog:error', error);
        return Promise.reject(error);
      },

      $_promiseResolver({ promise, loading, ignoreError, timer } = {}) {
        if (!promise) {
          return this.$_promiseRejection('Promise is required');
        }

        if (loading) {
          this.$_promiseUpdateLoading(loading, true);
        }

        return promise
          .catch((error) => {
            if (hasIgnoreError(ignoreError, error)) {
              throw error;
            }
            return this.$_promiseRejection(error);
          })
          .finally(() => {
            if (!loading) return;

            setTimeout(() => {
              this.$_promiseUpdateLoading(loading, false);
            }, timer || 0);
          });
      },
    },
  };
}
