import axios from 'axios';

import DateRange from '../utils/DateRange';

import UnauthorizedError from '../utils/UnauthorizedError';
import ForbiddenError from '../utils/ForbiddenError';

function verifyError(error) {
  const { response } = error;

  if (!response) throw error;
  if (response && (response.status === 401)) {
    throw new UnauthorizedError(response.data.message || response.data);
  }

  if (response && (response.status === 403)) {
    throw new ForbiddenError(response.data.message || response.data);
  }

  if (response.data) {
    const newError = new Error(response.data.message || response.data);
    newError.status = response.status;
    throw newError;
  }

  throw error;
}

function params(args) {
  return args.map((arg) => {
    if (!arg?.params) return arg;

    const paramsEntries = Object.entries(arg.params).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, JSON.stringify(value)];
      }

      if (DateRange.isDateRange(value)) {
        return [key, encodeURIComponent(value.toDateTimeString())];
      }

      return [key, value];
    });

    return { ...arg, params: Object.fromEntries(paramsEntries) };
  });
}

let baseURL = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOST}`;
if (process.env.VUE_APP_API_PORT) baseURL += `:${process.env.VUE_APP_API_PORT}`;
const API = axios.create({ baseURL, timeout: 600000 });

export function setApiAccessToken(accessToken) {
  if (accessToken) {
    API.defaults.headers.authorization = `Bearer ${accessToken}`;
  } else {
    delete API.defaults.headers.authorization;
  }
}

export function setApiTenant(tenant) {
  if (tenant) {
    API.defaults.headers['x-tenant'] = tenant;
  } else {
    delete API.defaults.headers['x-tenant'];
  }
}

export function setApiUserCustomer(userCustomer) {
  if (userCustomer) {
    API.defaults.headers['x-user-customer'] = userCustomer;
  } else {
    delete API.defaults.headers['x-user-customer'];
  }
}

let promises = 0;
function preventUnload(promise) {
  promises += 1;

  if (promises === 1) {
    window.onbeforeunload = () => 'Do you want to leave?';
  }

  return promise.finally(() => {
    promises -= 1;

    if (promises === 0) {
      window.onbeforeunload = null;
    }
  });
}

export default {
  baseURL: API.defaults.baseURL,
  get: (...args) => API.get(...params(args)).catch(verifyError),
  post: (...args) => preventUnload(API.post(...params(args)).catch(verifyError)),
  put: (...args) => preventUnload(API.put(...params(args)).catch(verifyError)),
  patch: (...args) => preventUnload(API.patch(...params(args)).catch(verifyError)),
  delete: (...args) => preventUnload(API.delete(...params(args)).catch(verifyError)),
};
