import ActivableMixin from './activable-mixin';

export default function ClickOutsideMixin({ ref, prop }) {
  return {
    mixins: [
      ActivableMixin(prop),
    ],

    methods: {
      $_clickOutside($event) {
        if (!this.isActive) return;

        if (!this.$refs[ref].contains($event.target)) {
          this.isActive = false;
        }
      },
    },

    watch: {
      isActive(active) {
        if (active) {
          document.addEventListener('click', this.$_clickOutside);
        } else {
          document.removeEventListener('click', this.$_clickOutside);
        }
      },
    },

    beforeDestroy() {
      document.removeEventListener('click', this.$_clickOutside);
    },
  };
}
