<template>
  <b2b-container id="loading" class="background-image" column align-center justify-center>
    <b2b-app-loading class="invitation__loading" />
  </b2b-container>
</template>

<script>
import B2bContainer from '../components/core/b2b-container.vue';
import B2bAppLoading from '../components/app/b2b-app-loading.vue';

export default {
  name: 'Loading',
  components: {
    B2bContainer,
    B2bAppLoading,
  },
};
</script>
