export const BREAKPOINT_MOBILE = 0;
export const BREAKPOINT_TABLET = Number(process.env.VUE_APP_BREAKPOIN_TABLET);
export const BREAKPOINT_LAPTOP = Number(process.env.VUE_APP_BREAKPOIN_LAPTOP);
export const BREAKPOINT_DESKTOP = Number(process.env.VUE_APP_BREAKPOIN_DESKTOP);
export const BREAKPOINT_ULTRAWIDE = Number(process.env.VUE_APP_BREAKPOIN_ULTRAWIDE);

export function isMobile() {
  return window.innerWidth < BREAKPOINT_TABLET;
}

export function isTablet() {
  return window.innerWidth >= BREAKPOINT_TABLET && window.innerWidth < BREAKPOINT_LAPTOP;
}

export function isLaptop() {
  return window.innerWidth >= BREAKPOINT_LAPTOP && window.innerWidth < BREAKPOINT_DESKTOP;
}

export function isDesktop() {
  return window.innerWidth >= BREAKPOINT_DESKTOP && window.innerWidth < BREAKPOINT_ULTRAWIDE;
}

export function isUltrawide() {
  return window.innerWidth >= BREAKPOINT_ULTRAWIDE;
}

export function currentBreakpoint() {
  if (isUltrawide()) return BREAKPOINT_ULTRAWIDE;
  if (isDesktop()) return BREAKPOINT_DESKTOP;
  if (isLaptop()) return BREAKPOINT_LAPTOP;
  if (isTablet()) return BREAKPOINT_TABLET;
  return BREAKPOINT_MOBILE;
}
