<template>
  <div>
    <slot name="activator" :on="activator" />

    <div
      ref="content"
      role="document"
      class="b2b-overlay"
      :class="classes"
    >
      <b2b-transition-fade>
        <div v-if="isActive" class="b2b-overlay__background" @click.stop="$_onClickOverlay" />
      </b2b-transition-fade>

      <component :is="$_transitionComponent" v-bind="$_transitionBind">
        <div
          v-if="isActive"
          class="b2b-overlay__content"
          :class="{
            'b2b-overlay__content--fullscreen': fullScreen,
          }"
        >
          <div class="b2b-overlay__content__wrap">
            <slot />
          </div>
        </div>
      </component>
    </div>
  </div>
</template>

<script>
import ActivableMixin from '../../mixins/base/activable-mixin';

import B2bTransitionFade from './transition/b2b-transition-fade.vue';
import B2bTransitionBounce from './transition/b2b-transition-bounce.vue';

let overlayCount = 0;

export default {
  name: 'b2b-overlay',

  components: {
    B2bTransitionFade,
    B2bTransitionBounce,
  },

  mixins: [
    ActivableMixin(),
  ],

  props: {
    persistent: Boolean,
    transition: {
      type: [Object, String],
      default: () => B2bTransitionBounce,
    },
    fullScreen: Boolean,
    classes: String,
  },

  computed: {
    $_transitionComponent() {
      if (typeof this.transition === 'object') return this.transition;
      return 'transition';
    },

    $_transitionBind() {
      if (typeof this.transition === 'object') return null;
      return { name: this.transition };
    },
  },

  methods: {
    $_onActivatorClick($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.isActive = true;
    },

    $_onClickOverlay() {
      if (!this.persistent) {
        this.isActive = false;
      } else {
        this.$el.classList.add('b2b-overlay--shake');

        setTimeout(() => {
          this.$el.classList.remove('b2b-overlay--shake');
        }, 128);
      }
    },

    $_onKeyPress($event) {
      if ($event.key === 'Escape') {
        this.$_onClickOverlay();
      }
    },

    $_detach() {
      if (this.hasDetached) return;

      const target = document.querySelector('[data-app]');
      target.appendChild(this.$refs.content);

      this.hasDetached = true;
    },
  },

  watch: {
    isActive(active) {
      if (active) {
        overlayCount += 1;
        document.querySelector('html').style.overflow = 'hidden';
        document.addEventListener('click', this.$_clickOutside);

        window.addEventListener('keyup', this.$_onKeyPress);

        this.$nextTick(() => {
          this.$_detach();
        });
      } else {
        overlayCount -= 1;

        if (overlayCount === 0) {
          document.querySelector('html').style.overflow = null;
          document.removeEventListener('click', this.$_clickOutside);

          window.removeEventListener('keyup', this.$_onKeyPress);
        }
      }
    },
  },

  created() {
    this.activator = {
      click: this.$_onActivatorClick,
    };
  },

  beforeDestroy() {
    document.removeEventListener('click', this.$_clickOutside);
    window.removeEventListener('keyup', this.$_onKeyPress);
  },
};
</script>

<style lang="scss">
.b2b-overlay {
  &__background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    position: fixed;

    opacity: 0.46;
    background-color: rgb(33, 33, 33);
  }

  &__content {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    padding: 16px;
    position: fixed;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    pointer-events: none;

    &__wrap {
      max-width: 100%;
      max-height: 100%;
      pointer-events: auto;
    }

    &--fullscreen {
      .b2b-card__actions {
        min-height: fit-content;
      }
    }

    &--fullscreen &__wrap {
      height: 100%;
    }
  }

  &--shake &__content {
    animation: b2b-overlay-shake 128ms;
  }
}

@keyframes b2b-overlay-shake {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.01);
  }
}
</style>
