export default [
  {
    path: '/companies',
    name: 'CompaniesIndex',
    component: () => import(/* webpackChunkName: "companies" */ '../../views/CompaniesIndex.vue'),
    meta: {
      requiresMaster: true,
      config: {
        header: true,
      },
    },
  },

  {
    path: '/companies/new',
    name: 'CompaniesNew',
    component: () => import(/* webpackChunkName: "company" */ '../../views/CompaniesNew.vue'),
    meta: {
      requiresMaster: true,
      config: {
        header: true,
        back: { name: 'CompaniesIndex' },
      },
    },
  },

  {
    path: '/companies/:id',
    name: 'CompaniesShow',
    component: () => import(/* webpackChunkName: "company" */ '../../views/CompaniesShow.vue'),
    meta: {
      requiresMaster: true,
      config: {
        header: true,
        back: { name: 'CompaniesIndex' },
      },
    },
  },
];
