function extractDate(date) {
  const value = new Date(date);

  return {
    day: String(value.getUTCDate()).padStart(2, '0'),
    month: String(value.getUTCMonth() + 1).padStart(2, '0'),
    year: String(value.getUTCFullYear()).padStart(4, '0'),
  };
}

export function toBrazilianString(date) {
  const { day, month, year } = extractDate(date);
  return `${day}/${month}/${year}`;
}

export function toAmericanString(date) {
  const { day, month, year } = extractDate(date);
  return `${year}-${month}-${day}`;
}
