<template>
  <div class="b2b-dialog-envio-overflow">
    <div class="b2b-envio-customers--loading" v-if="loading">
      <b2b-loading />
    </div>

    <b2b-data-table v-else v-bind="$_bind" v-on="$listeners" activeScroll>
      <template #headers:checkbox >
        <b2b-checkbox
          v-model="checkBoxAll"
          ref="selectAllCheckBox"
          @change="$_onChangeCheckAllBox" />
      </template>

      <template #items:checkbox="{ item }">
        <b2b-checkbox
          :value="item"
          :ref="item.cnpj"
          v-model="emailsCheck"
          @change="$_onChangeEmailsCheck($event)"
        />
      </template>

      <template #items:dataAtualizacao="{ item }">
        {{ item.dataAtualizacao | date }}
      </template>

      <template #expandable="{ item }">
        <slot name="expandable" :item="item" />
      </template>

      <template #footer>
       <b2b-data-table-pagination
          v-model="$_page"
          :pages="pages"
          :total="total"
          :count="items.length"
          :perPage="100"
          :disabled="loading" />
      </template>
    </b2b-data-table>
  </div>
</template>

<script>
import ActionResolver from '../../mixins/action-resolver';
import DataTableMixin from '../../mixins/components/data-table-mixin';

import i18n from '../../plugins/i18n';

import B2bCheckbox from '../core/form/b2b-checkbox.vue';
import B2bDataTable from '../core/table/b2b-data-table.vue';
import B2bDataTablePagination from '../core/table/b2b-data-table-pagination.vue';
import B2bLoading from '../core/b2b-loading.vue';

export default {
  name: 'b2b-data-table-envios',

  components: {
    B2bCheckbox,
    B2bDataTable,
    B2bDataTablePagination,
    B2bLoading,
  },

  mixins: [
    ActionResolver(),
    DataTableMixin({
      ignoreAltTab: true,

      watcher: {
        invitations: {
          type: String,
          key: 'invitations',
          label: i18n.tc('resources.envite.resource'),
        },

        state: {
          type: String,
          key: 'state',
          label: i18n.tc('labels.state', 1),
        },

        cities: {
          type: Array,
          key: 'cities',
          label: i18n.tc('labels.city', 2),
        },

        updateDate: {
          type: String,
          key: 'updateDate',
          label: i18n.t('labels.updateDate', 1),
        },
      },
    }),
  ],

  props: {
    actions: Boolean,
    disabled: Boolean,

    invitations: String,
    state: String,
    cities: Array,
    updateDate: String,
    totalEnvios: Object,
  },

  data() {
    return {
      loading: false,

      checkBoxAll: false,
      disabledButtonEnvio: false,
      titleButtonEnvio: this.$t('labels.envioCustomers'),
      loadingButtonEnvio: false,

      headers: [
        {
          text: ' ',
          value: 'checkbox',
          type: String,
        },
        {
          text: this.$t('resources.envio.cnpj', 1),
          value: 'cnpj',
          type: String,
        },
        {
          text: this.$t('resources.envio.nomeFantasia', 1),
          value: 'nomeFantasia',
          type: String,
        },
        {
          text: this.$t('resources.envio.usuarioEmail', 1),
          value: 'usuarioEmail',
          type: String,
        },
        {
          text: this.$t('resources.envio.dataAtualizacao', 1),
          value: 'dataAtualizacao',
          type: Date,
        },
      ],

      emailsCheck: [],
    };
  },

  methods: {
    async $_load(actionParams) {
      this.loading = true;
      const { id } = this.$_company;

      const params = {
        ...actionParams,
        invitations: this.invitations,
        limit: 100,
        companyId: id,
        getCities: false,
      };

      const result = await this.$_actionResolver({
        action: 'customers/getEnvioCustomers',
        params,
      });

      this.loading = false;

      this.$emit('get-envioFunction');

      return result;
    },

    $_onChangeCheckAllBox() {
      if (!this.$refs.selectAllCheckBox.checked) {
        this.checkBoxAll = true;
        this.emailsCheck = this.items;
      } else {
        this.checkBoxAll = false;
        this.emailsCheck = [];
      }

      this.$emit('get-emails', this.emailsCheck);
    },

    $_onChangeEmailsCheck() {
      this.$emit('get-emails', this.emailsCheck);
      this.checkBoxAll = false;
    },
  },

  computed: {
    $_company() {
      return this.$store.getters['session/company'] || {};
    },
  },

  watch: {
    invitations(value) {
      this.$_invitations = value;
    },
    state(value) {
      this.$_state = value;
    },
    cities(value) {
      this.$_cities = value;
    },
    updateDate(value) {
      this.$_updateDate = value;
    },
    totalEnvios() {
      const array = [];
      this.items.forEach((item) => {
        if (!this.emailsCheck.includes(item)) {
          array.push(item);
        }
      });

      this.items = array;
      this.checkBoxAll = false;

      this.emailsCheck = [];
      this.$emit('get-emails', this.emailsCheck);
    },
  },
};
</script>

<style scoped>
.b2b-envio-customers--loading .b2b-loader {
  left:50%;
  top:50%;
  position: absolute;
  margin-left: -40px;
  margin-top: 40px;
}
</style>
