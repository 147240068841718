<template functional>
  <div class="b2b-container" v-bind="data.attrs"
  :class="{
    [data.staticClass]: data.staticClass,
    'b2b-container--row': props.row,
    'b2b-container--column': props.column,
    'b2b-container--align-center': props.alignCenter,
    'b2b-container--justify-center': props.justifyCenter,
  }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'b2b-container',
  props: {
    row: Boolean,
    column: Boolean,
    alignCenter: Boolean,
    justifyCenter: Boolean,
  },
};
</script>

<style lang="scss">
@import '../../styles/break-points.scss';

.b2b-container {
  padding: 16px;
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;

  &--row {
    flex-direction: row;
  }

  &--column {
    flex-direction: column;
  }

  &--align-center {
    align-items: center;
  }

  &--justify-center {
    justify-content: center;
  }
}
</style>
