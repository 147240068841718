import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('limitePorPedido', ({ Api, resource }) => ({
  actions: {
    async getLimit(_, { id }) {
      const { data } = await Api.get(`${resource}/${id}/company`);
      return data;
    },

    async updateLimit(_, { id, form }) {
      const { data } = await Api.put(`${resource}/${id}/company`, form);
      return data;
    },
  },
}));
