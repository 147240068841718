export default function WindowVisibilityResolver({ method, immediate = null }) {
  return {
    created() {
      if (immediate != null) {
        this[method](immediate);
      }

      this.$hub.on('window:visibility', this[method]);
    },

    beforeDestroy() {
      this.$hub.off('window:visibility', this[method]);
    },
  };
}
