import ModelMixin from '../base/model-mixin';

import ActionResolver from '../action-resolver';
import QueryWatchResolver from '../query-watch-resolver';
import WindowVisibilityResolver from '../window-visibility-resolver';

export default function DataTableMixin(options = {}) {
  const { handler, ...watchers } = options.watcher || {};

  return {
    mixins: [
      ModelMixin(Array),

      ActionResolver(),

      QueryWatchResolver({
        page: Number,
        search: {
          type: String,
          key: 's',
        },
        ...watchers,
      }, function watchResolverHandler(key) {
        if (!handler || handler(key)) {
          this.load();
        }
      }),

      WindowVisibilityResolver({
        method: '$_onWindowVisibilityChange',
      }),
    ],

    props: {
      id: {
        type: String,
        default: null,
      },
      expandable: Boolean,
      selectable: Boolean,
      noCard: Boolean,
      noSearch: Boolean,
      scrollTo: String,
      fillHeight: Boolean,
    },

    data() {
      return {
        loading: false,
        items: [],
        pages: null,
        total: null,
        filters: watchers,
      };
    },

    computed: {
      $_bind() {
        return {
          id: this.id,
          ref: 'datatable',
          headers: this.headers,
          items: this.items,
          expandable: this.expandable,
          noCard: this.noCard,
          selectable: this.selectable,
          fillHeight: this.fillHeight,
        };
      },
    },

    methods: {
      load(ignoreScroll) {
        return this.$_load(this.$_mapQuery())
          .then(({ items, pages, count }) => {
            this.items = items;
            this.pages = pages;
            this.total = count;

            if (ignoreScroll) return;

            this.$nextTick(() => {
              if (this.scrollTo) {
                const element = document.querySelector(this.scrollTo);

                if (element) {
                  element.scrollIntoView();
                  return;
                }
              }

              window.scrollTo(0, 0);
            });
          })
          .finally(() => {
            if (this.pages > 0 && this.$_page > this.pages) this.$_page = 1;
          });
      },

      reload() {
        if (this.$_page === 1) {
          this.load();
        } else {
          this.$_page = 1;
          // Não tem necessidade de load
          // A alteração de página já dispara o mesmo
        }
      },

      $_onWindowVisibilityChange($visible) {
        if (!options?.ignoreAltTab) {
          if (!$visible) return;

          this.load(true);
        }
      },
    },

    watch: {
      filters: {
        deep: true,
        handler() {
          this.reload();
        },
      },
    },

    mounted() {
      this.load();
    },
  };
}
