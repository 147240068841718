import ResourcePattern from '../utils/resource-pattern';

export default ResourcePattern('pedidos', ({ Api, resource }) => ({
  actions: {
    async items(_, { orderId, ...params }) {
      const { data, headers } = await Api.get(`${resource}/${orderId}/itens`, { params });
      return {
        items: data,
        pages: headers['x-total-page'],
      };
    },

    async totals(_, { ...params }) {
      const { data } = await Api.get(`${resource}/totalizadores`, { params });
      return data;
    },

    async exportOrders(_, params) {
      const { data } = await Api.get(`${resource}/export`, { params });
      return {
        items: data,
      };
    },
  },
}));
