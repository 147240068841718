<template functional>
  <transition name="bounce">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'b2b-transition-bounce',
};
</script>

<style lang="scss">
.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
