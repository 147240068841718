<template>
  <div class="b2b-button__icon__info">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'b2b-button-icon-info',
};
</script>

<style lang="scss">
.b2b-button__icon__info {
  position: absolute;
  right: calc(100% - 3px);
  height: 24px;
  line-height: 24px;
  border: 1px solid currentColor;
  border-right: none;
  border-radius: 12px 0 0 12px;
  padding: 0 8px;
  z-index: -1;
  white-space: nowrap;
}
</style>
