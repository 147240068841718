var render = function render(_c,_vm){return _c(_vm.props.cover || _vm.props.contain ? 'div' : 'img',_vm._g(_vm._b({tag:"component",staticClass:"b2b-image",class:{
    ..._vm.data.class,
    [_vm.data.staticClass]: !!_vm.data.staticClass,
    'b2b-image--cover': _vm.props.cover,
    'b2b-image--contain': _vm.props.contain,
    'b2b-image--outline': _vm.props.outline,
    'b2b-image--cursor': _vm.props.cursor,
  },style:({
    ..._vm.data.style,
    backgroundImage: !_vm.props.cover && !_vm.props.contain ? undefined
      : `url('${(_vm.props.src || '/img/image-404.jpg')}')`,
  }),attrs:{"src":_vm.props.cover || _vm.props.contain ? undefined : (_vm.props.src || '/img/image-404.jpg'),"alt":_vm.props.cover || _vm.props.contain ? undefined : _vm.props.alt}},'component',{
    ref: _vm.data.ref,
    ..._vm.data.attrs
  },false),_vm.listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }