<template functional>
  <div class="spacer" />
</template>

<script>
export default {
  name: 'b2b-spacer',
};
</script>

<style>
.spacer {
  flex: 1;
}
</style>
