<template>
  <div class="b2b-list-customers">
    <b2b-divider v-text="$t('resources.sign-in.select-customer')" />

    <b2b-list>
      <template v-for="customer in $_customers">
        <b2b-list-item :key="customer.customerId" v-on:click="$_onClickItem(customer)">
          <b2b-list-item-title v-text="customer.tradingName" v-if="Boolean(customer.tradingName)" />
          <b2b-list-item-title v-text="customer.companyName" v-else />

          <b2b-list-item-subtitle>{{ customer.cnpj | document }}</b2b-list-item-subtitle>
        </b2b-list-item>
      </template>
    </b2b-list>
  </div>
</template>

<script>
import B2bDivider from '../core/b2b-divider.vue';
import B2bList from '../core/list/b2b-list.vue';
import B2bListItem from '../core/list/b2b-list-item.vue';
import B2bListItemTitle from '../core/list/b2b-list-item-title.vue';
import B2bListItemSubtitle from '../core/list/b2b-list-item-subtitle.vue';

export default {
  name: 'b2b-list-customers',

  components: {
    B2bDivider,
    B2bList,
    B2bListItem,
    B2bListItemTitle,
    B2bListItemSubtitle,
  },

  computed: {
    $_customers() {
      if (!this.$store.getters['session/company']) return [];
      return this.$store.getters['session/company'].customers;
    },
  },

  methods: {
    $_onClickItem(item) {
      this.$emit('click:item', item);
    },
  },
};
</script>

<style lang="scss">
.b2b-list-customers {
  max-height: 100%;
  overflow-y: auto;
  background-color: inherit;

  &__header {
    top: 0;
    position: sticky;
    background-color: inherit;
  }
}
</style>
