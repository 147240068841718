export default (value) => {
  if (!value) return '';

  let address = '';

  if (value.logradouro) {
    if (address) {
      address += ' - ';
    }

    address += value.logradouro;
  }

  if (value.numero) {
    if (address) {
      address += ', ';
    }

    address += value.numero;
  }

  if (value.complemento) {
    if (address) {
      address += ' | ';
    }

    address += value.complemento;
  }

  return address.trim();
};
