export default function ButtonMixin() {
  return {
    props: {
      to: [String, Object],
      exact: Boolean,
      active: Boolean,
      disabled: Boolean,
      loading: Boolean,
      type: {
        type: String,
        default: 'button',
      },
    },

    computed: {
      $_disabled() {
        return this.disabled || this.loading;
      },
    },

    methods: {
      $_onClick($event) {
        $event.stopPropagation();
        this.$emit('click', $event);
      },
    },

    created() {
      this.$_listeners = {
        click: this.$_onClick,
      };
    },
  };
}
