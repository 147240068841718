import Vue from 'vue';
import Vuex from 'vuex';

import { setLangue } from '../plugins/i18n';

const modules = require.context('./modules', true, /\.js$/);

Vue.use(Vuex);

export default new Vuex.Store({
  modules: modules.keys().reduce((obj, moduleKey) => Object.assign(obj, {
    [moduleKey.replace(/^\.\/|\.js$/g, '')]: modules(moduleKey).default,
  }), {}),

  state: {
    swPrompt: null,
  },

  actions: {
    setup({ dispatch }) {
      return Promise.all([
        setLangue(navigator.language || navigator.userLanguage),
        dispatch('session/setup'),
      ]);
    },
  },

  mutations: {
    SET_SW_PROMPT(state, swPrompt) {
      state.swPrompt = swPrompt;
    },
  },
});
