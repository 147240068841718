<template>
  <div class="b2b-pagination">
    <b2b-button-icon
      :small="$_isSmall"
      :disabled="$_disableLeftActions"
      @click.stop="$_onClickFirstPage"
    >
      <chevrons-left-icon />
    </b2b-button-icon>
    <b2b-button-icon
      class="mobile-and-up--hide"
      :small="$_isSmall"
      :disabled="$_disableLeftActions"
      @click.stop="$_onClickPreviousPage">
      <chevron-left-icon />
    </b2b-button-icon>

    <template v-for="page in $_pages">
      <b2b-button-icon
        :key="page"
        :small="$_isSmall"
        :active="page === value"
        :disabled="disabled || loading"
        :loading="loading && (page === value)"
        @click.stop="$_emitPage(page)">
        <span class="b2b-pagination__number">{{ page }}</span>
      </b2b-button-icon>
    </template>

    <b2b-button-icon
      class="mobile-and-up--hide"
      :small="$_isSmall"
      :disabled="$_disableRightActions"
      @click.stop="$_onClickNextPage">
      <chevron-right-icon />
    </b2b-button-icon>
    <b2b-button-icon
      :small="$_isSmall"
      :disabled="$_disableRightActions"
      @click.stop="$_onClickLastPage"
    >
      <chevrons-right-icon />
    </b2b-button-icon>
  </div>
</template>

<script>
import { ChevronsLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronsRightIcon } from 'vue-feather-icons';

import BreakpointMixin from '../../mixins/base/breakpoints-mixin';

import B2bButtonIcon from './buttons/b2b-button-icon.vue';

export default {
  name: 'b2b-pagination',
  mixins: [
    BreakpointMixin(),
  ],
  props: {
    value: {
      type: [String, Number],
      default: 1,
    },
    pages: {
      type: [String, Number],
      default: 1,
    },
    disabled: Boolean,
    loading: Boolean,
    small: Boolean,
  },
  components: {
    B2bButtonIcon,
    ChevronsLeftIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronsRightIcon,
  },
  computed: {
    $_isSmall() {
      return this.small || this.$_isMobile;
    },
    $_disableLeftActions() {
      if (this.disabled || this.loading) return true;

      return Number(this.value) === 1;
    },
    $_disableRightActions() {
      if (this.disabled || this.loading) return true;

      const pages = Number(this.pages);
      if (pages <= 0) return true;

      const page = Number(this.value);
      return page === pages;
    },
    $_pages() {
      const max = Number(this.pages);
      if (max === 0) return [];

      const maxButtons = this.$_isMobile ? 3 : 5;
      const limit = parseInt(maxButtons / 2, 10);
      const page = Number(this.value);

      let left = page - limit;
      let right = page + limit;

      if (left < 1) {
        right += 1 - left;
        left = 1;
      }

      if (right > max) {
        left -= right - max;
        right = max;
      }

      if (left < 1) left = 1;

      const length = right - left + 1;
      return [...new Array(length)].map((_, i) => i + left);
    },
  },
  methods: {
    $_emitPage(page) {
      if (page === this.value) return;
      this.$emit('input', page);
    },
    $_onClickFirstPage() {
      this.$_emitPage(1);
    },
    $_onClickPreviousPage() {
      const previous = Number(this.value) - 1;
      if (previous < 1) return;
      this.$_emitPage(previous);
    },
    $_onClickNextPage() {
      const next = Number(this.value) + 1;
      if (next > Number(this.pages)) return;
      this.$_emitPage(next);
    },
    $_onClickLastPage() {
      this.$_emitPage(Number(this.pages));
    },
  },
};
</script>

<style lang="scss">
.b2b-pagination {
  margin: 8px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__number {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
