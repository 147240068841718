<template>
  <div class="b2b-avatar" @click="$_onClick"
    :style="{
      width: $_size,
      height: $_size,
    }"
    :class="{
      'b2b-avatar--flat': this.flat,
    }"
  >
    <user-icon v-if="!src" :size="String(size / 2)" />
    <b2b-image v-else class="b2b-avatar__img" :src="src" cover />
  </div>
</template>

<script>
import { UserIcon } from 'vue-feather-icons';

import B2bImage from './b2b-image.vue';

function toStyleValue(value) {
  if (!value) return undefined;
  return Number.isNaN(+value) ? value : `${Number(value)}px`;
}

export default {
  name: 'b2b-avatar',
  components: {
    UserIcon,
    B2bImage,
  },
  props: {
    src: String,
    size: {
      type: [String, Number],
      default: 64,
    },
    flat: Boolean,
  },
  computed: {
    $_size() {
      return !this.size ? undefined : toStyleValue(this.size);
    },
  },
  methods: {
    $_onClick($event) {
      this.$emit('click', $event);
    },
  },
};
</script>

<style lang="scss">
.b2b-avatar {
  padding: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 1px solid $colorMainPrimary;

  &__img {
    height: 100%;
    border-radius: 50%;
  }

  &--flat {
    padding: 0;
    border: none;
  }
}
</style>
