export default [
  {
    path: '/customers',
    name: 'CustomersIndex',
    component: () => import(/* webpackChunkName: "customers-index" */ '../../views/CustomersIndex.vue'),
    meta: {
      requiresAdmin: true,
      config: {
        header: true,
      },
    },
  },

  {
    path: '/customers/:id',
    name: 'CustomersShow',
    component: () => import(/* webpackChunkName: "customers-show" */ '../../views/CustomersShow.vue'),
    meta: {
      requiresAdmin: true,
      config: {
        header: true,
        back: { name: 'CustomersIndex' },
      },
    },
  },
];
