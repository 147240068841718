function encodeKey(key) {
  if (process.env.VUE_APP_ENCODE_STORAGE !== 'true') return key;
  return btoa(key);
}

function encodeValue(value) {
  if (process.env.VUE_APP_ENCODE_STORAGE !== 'true') return value;
  return btoa(value);
}

function decodeValue(value) {
  if (process.env.VUE_APP_ENCODE_STORAGE !== 'true') return value;
  return atob(value);
}

export async function removeFromStorage(key) {
  return localStorage.removeItem(encodeKey(key));
}

export async function setInStorage(key, value) {
  if (!value) return removeFromStorage(key);

  const jsonValue = JSON.stringify(value);
  return localStorage.setItem(encodeKey(key), encodeValue(jsonValue));
}

export async function getFromStorage(key) {
  const encodedValue = await localStorage.getItem(encodeKey(key));
  return encodedValue ? JSON.parse(decodeValue(encodedValue)) : null;
}

export function clearStorage() {
  return localStorage.clear();
}
