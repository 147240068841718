<template functional>
  <component role="listitem" class="list__item" v-on="listeners"
    :key="data.key"
    :is="props.to ? 'router-link' : 'div'"
    :to="props.to"
    :class="{
      'list__item--active': props.value,
      'list__item--active-exact': props.exact,
      'list__item--clickable': !!listeners.click || props.to,
      'list__item--hoverable': !!(listeners.click || props.to || props.hoverable),
    }">

    <slot name="avatar" />

    <div class="list__item__content">
      <slot />
    </div>

    <slot name="action" />
  </component>
</template>

<script>
export default {
  name: 'b2b-list-item',
  props: {
    value: Boolean,
    hoverable: Boolean,
    to: [String, Object],
    exact: Boolean,
  },
};
</script>

<style lang="scss">
.list__item {
  overflow: auto;
  padding: 0 16px;

  display: flex;
  flex-direction: row;

  &--clickable {
    cursor: pointer;
  }

  &--hoverable {
    &:hover {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  &--active,
  &.router-link-active:not(&--active-exact),
  &--active-exact.router-link-exact-active {
    background-color: rgba(0, 0, 0, 0.24);
  }

  &__content {
    flex: 1;
    padding: 16px 0;
    max-width: 100%;
  }

  div + &__content {
    margin-left: 16px;
  }
}
</style>
