<template>
  <header class="b2b-header" :class="{
      'b2b-header--top': scrollTop,
    }">
    <template v-if="!loading">
      <div v-if="!!$_userDescription" class="b2b-header__name">
        <label v-text="$_userDescription" />
      </div>

      <div class="b2b-header__toolbar" v-if="!loading">
        <b2b-button-icon v-if="$_backButton" :to="$_backButton">
          <arrow-left-icon />
        </b2b-button-icon>

        <router-link v-if="!$_isMobile || !$_backButton" to="/redirect" class="ml--3">
          <b2b-flex flex="0" align="center" row>
            <template v-if="$_company.logo">
              <b2b-image
                class="b2b-header__logo"
                :src="$_company.logo"
                :alt="$_company.tradingName" />
            </template>

            <h1 v-if="!$_company.logo" class="b2b-header__company" v-text="$_company.tradingName" />
          </b2b-flex>
        </router-link>

        <div style="flex: 1" />

        <b2b-flex flex="0" align="center" row>
          <b2b-tooltip
            v-if="$_isCustomer"
            :label="$t('resources.menu.tooltip.cart')">
            <template #activator>
              <b2b-button-icon
                v-if="$_isCustomer"
                :to="{ name: 'ShoppingCart' }"
                :activeButton="$_cartExistItem">
                <shopping-cart-icon />

                <template #info v-if="!$_exibirPreco">
                  <b2b-button-icon-info v-text="$_cartInfo" />
                </template>
              </b2b-button-icon>
            </template>
          </b2b-tooltip>

          <b2b-bell-menu class="ml--2" v-if="$_isCustomer" />

          <b2b-tooltip
            v-if="!$_isMobile && $_isCustomer"
            :label="$t('resources.menu.favorites')">
            <template #activator>
              <b2b-button-icon
                v-if="!$_isMobile && $_isCustomer"
                :to="{ name: 'FavoritesIndex' }"
                class="ml--2"
              >
                <heart-icon />
              </b2b-button-icon>
            </template>
          </b2b-tooltip>

          <b2b-profile-menu class="ml--2" />
        </b2b-flex>
      </div>

      <div>
        <slot :scrollTop="scrollTop" />
      </div>
    </template>
  </header>
</template>

<script>
import { ArrowLeftIcon, ShoppingCartIcon, HeartIcon } from 'vue-feather-icons';

import ActionResolver from '../../mixins/action-resolver';
import BreakpointMixin from '../../mixins/base/breakpoints-mixin';
import WindowVisibilityResolver from '../../mixins/window-visibility-resolver';

import B2bFlex from '../core/b2b-flex.vue';
import B2bButtonIcon from '../core/buttons/b2b-button-icon.vue';
import B2bButtonIconInfo from '../core/buttons/b2b-button-icon-info.vue';
import B2bProfileMenu from './b2b-profile-menu.vue';
import B2bImage from '../core/b2b-image.vue';
import B2bBellMenu from './b2b-bell-menu.vue';
import B2bTooltip from '../core/b2b-tooltip.vue';

export default {
  name: 'b2b-header',

  components: {
    B2bFlex,
    B2bButtonIcon,
    ArrowLeftIcon,
    ShoppingCartIcon,
    HeartIcon,
    B2bButtonIconInfo,
    B2bProfileMenu,
    B2bImage,
    B2bBellMenu,
    B2bTooltip,
  },

  mixins: [
    ActionResolver(),

    BreakpointMixin(),

    WindowVisibilityResolver({
      method: '$_onWindowVisibilityChange',
    }),
  ],

  props: {
    loading: Boolean,
  },

  data() {
    return {
      scrollTop: true,
    };
  },

  computed: {
    $_exibirPreco() {
      return Boolean(this.$store.getters['session/company'].esconderPreco) && this.$store.getters['session/isCustomer'];
    },

    $_exibirEstoque() {
      return Boolean(this.$store.getters['session/company'].obrigaEstoque) && this.$store.getters['session/isCustomer'];
    },

    $_cartExistItem() {
      if (this.$_exibirPreco) {
        return Boolean(this.$store.getters['shopping-cart/total']);
      }

      return false;
    },

    $_userDescription() {
      if (this.$store.getters['session/isCustomer']) {
        return this.$store.getters['session/customer']?.companyName;
      }

      return null;
    },

    $_company() {
      return this.$store.getters['session/company'] || {};
    },

    $_customer() {
      return this.$store.getters['session/customer'];
    },

    $_isCustomer() {
      return this.$store.getters['session/isCustomer'];
    },

    $_cartInfo() {
      const total = this.$store.getters['shopping-cart/total'];
      return this.$n(total || 0, 'currency');
    },

    $_backButton() {
      return this.$route.query?.back ?? this.$route.meta?.config?.back;
    },
  },

  methods: {
    $_loadTotals() {
      if (!this.$_customer || this.loading) return;

      this.$nextTick(() => {
        this.$_actionResolver({
          action: 'shopping-cart/totals',
        });
      });
    },

    $_onWindowVisibilityChange($visible) {
      if (!$visible) return;

      this.$_loadTotals();
    },
  },

  created() {
    this.scrollTop = document.body.scrollTop === 0;

    window.onscroll = () => {
      this.scrollTop = (document.body.scrollTop || document.documentElement.scrollTop) === 0;
    };
  },

  watch: {
    $_customer() {
      this.$_loadTotals();
    },

    loading() {
      this.$_loadTotals();
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/break-points.scss';

$logoSize: 48px;

.b2b-header {
  z-index: 10;

  top: 0;
  width: 100%;
  position: sticky;

  background-color: $colorBackgroundDefault;

  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  &--top {
    box-shadow: none;
  }

  &__name {
    height: 24px;
    padding: 0 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: bold;
    text-align: center;

    background-color: rgba(0, 0, 0, 0.12);

    label {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 16px;
    height: $headerHeight - 24px;
    line-height: $headerHeight - 24px;
  }

  &__logo {
    margin-right: 16px;
    max-height: $logoSize;
    max-width: $logoSize * 2;
  }

  &__company {
    display: none;
  }

  @include breakpointMin('laptop') {
    &__company {
      display: inline-block;
    }
  }
}
</style>
