export default function MutationObserverResolver() {
  return {
    methods: {
      $_observeResolver(target, callback) {
        const observer = new MutationObserver(callback);
        observer.observe(target, {
          attributes: true,
          childList: true,
          characterData: true,
          subtree: true,
        });

        return observer;
      },
    },
  };
}
